(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('bulkActionItem', bulkActionItem);

    function bulkActionItem () {
        return {
            restrict: 'AE',
            replace: true,
            require: '^^bulkActionsList',
            transclude: true,
            template: '<li ng-transclude></li>',
            scope: {
                action: '&'
            },
            link: function (scope, element, attrs, bulkActionsListController) {
                scope.bulkActionsListController = bulkActionsListController;
            },
            controller: BulkActionItemController
        };
    }

    BulkActionItemController.$inject = ['$scope', '$element', '$attrs', 'bulkActionsService'];

    function BulkActionItemController ($scope, $element, $attrs, bulkActionsService) {
        $element.on('click', handleAnchorClick);

        $element.on('$destroy', function () {
            $element.off('click', handleAnchorClick);
        });

        function handleAnchorClick (e) {
            $scope.action({ items: bulkActionsService.getInstance($scope.bulkActionsListController.bulkActionsId).items });
            $scope.bulkActionsListController.listShowing = false;
        }
    }
}());

