/*
Formatted Money Directive

Use:
<input formatted-money />

Model is formatted as a float, rounded to two decimal places
View is formatted as a string, including commas

Does not accept negative values.

*/

(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('formattedMoney', formattedMoneyDirective);

    formattedMoneyDirective.$inject = ['moneyUtils'];

    function formattedMoneyDirective (moneyUtils) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function formattedMoneyLinkFn (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (value) {
                    if (!value) {
                        return undefined;
                    }

                    var endsWithPoint = value.split('').pop() === '.';
                    var modelValue = moneyUtils.moneyStringToFloat(value);
                    var viewValue = moneyUtils.moneyFloatToString(modelValue);

                    viewValue = viewValue + (endsWithPoint && viewValue.indexOf('.') === -1 ? '.' : '');

                    if (viewValue !== value) {
                        ngModel.$viewValue = viewValue;
                        ngModel.$render();
                    }

                    return modelValue;
                });

                ngModel.$formatters.push(function (value) {
                    return moneyUtils.moneyFloatToString(value);
                });
            }
        };
    }
}());
