(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('ProductionTaskSpecificationService', ProductionTaskSpecificationService);

    ProductionTaskSpecificationService.$inject = ['$http', '$q', 'restRootUrl'];

    function ProductionTaskSpecificationService ($http, $q, restRootUrl) {
        var service = {
            getTaskSpecificationsForOperationItemId: getTaskSpecificationsForOperationItemId,
            saveTaskSpecifications: saveTaskSpecifications,
            getAllProductionTaskTypes: getAllProductionTaskTypes
        };

        return service;

        function getTaskSpecificationsForOperationItemId (supplierId, operationItemId, format) {
            var url = restRootUrl + '/productionTaskSpecifications/search';
            var q = 'supplierId=' + supplierId;
            if (format == 'large') {
                q += ':lfOperationItemId=' + operationItemId;
            } else {
                q += ':operationItemId=' + operationItemId;
            }
            var requestConfig = {
                method: 'GET',
                url: url,
                params: {
                    q: q,
                    embeddedAssociations: 'productionTimeFormulas,productionTaskType,productionTaskMaterialSpecifications'
                }
            };
            return $http(requestConfig);
        }

        function saveTaskSpecifications (operationItemId, productionTaskSpecifications, format) {
            var url = restRootUrl + '/operationItems/' + operationItemId + '/productionTaskSpecifications';
            if (format == 'large') {
                url = restRootUrl + '/largeFormatOperationItems/' + operationItemId + '/productionTaskSpecifications';
            }
            var data = productionTaskSpecifications;
            return $http({
                url: url,
                method: 'PATCH',
                data: data
            });
        }

        function getAllProductionTaskTypes () {
            var url = restRootUrl + '/productionTaskTypes/search?size=500';
            var requestConfig = {
                method: 'GET',
                url: url
            };
            return $http(requestConfig);
        }
    }
}());
