/*
Markup example / explaination

<div
    bulk-actions-list                                           // [required] primary directive hook
    bulk-actions-id="my_unique_id"                              // [optional if only bulk-action on page] the id is used to associate the various controls spread around the page
    trigger-title="'Perform bulk actions on selected things'"   // [optional] texts that appears when hovering the control button
    list-title="'Bulk actions for certain things'"              // [optional] title text within the bulk action bubble
>
    // Within the bulk-actions-list directive,
    // we add bulk-action-item directives -
    // one for each bulk action we wish to add
    <div
        bulk-action-item                                        // [required] primary directive hook
        action="vm.doSomeBulkAction(items)"                     // [required] assign a controller function that performs the bulk action - this function will be passed an array of selected objects
    >
        [this space for rent]                                   // [required] put arbitrary content within the bulk-action-item, usually an anchor, button etc... don't assign any click events to those elements, the bulk action is handled via the 'action' attribute
    </div>
</div>

(elsewhere on the page...)

// The bulk-actions-select-all directive creates
// a checkbox that selects all items in the associated list
<div
    bulk-actions-select-all                                     // [required] primary directive hook
    bulk-actions-id="my_unique_id"                              // [optional if only one bulk-action on page] the id is used to associate the various controls spread around the page
    watch-for-reset="vm.someListOfThings"                       // [required-ish] feed it the on screen collection we're performing bulk actions on. The presence of this attribute facilitates the resetting of a seleccted checkbox if the collection changes (pagination etc...)
></div>

(meanwhile...)

//The bulk-actions-include directive creates
// a checkbox that selects an object to perform
// bulk actions on
<div
    bulk-actions-include="someItem"                             // [required] primary directive hook. Its value is required - the object that will be selected for bulk action
    bulk-actions-id="my_unique_id"                              // [optional if only one bulk-action on page] the id is used to associate the various controls spread around the page
></div>

*/

(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('bulkActionsList', bulkActionsList);

    function bulkActionsList () {
        return {
            restrict: 'AE',
            replace: true,
            transclude: true,
            templateUrl: '/templates/bulk-actions-list.template.html',
            scope: {
                triggerTitle: '=',
                listTitle: '=',
                bulkActionsId: '@'
            },
            controllerAs: 'vm',
            bindToController: true,
            controller: BulkActionsListController
        };
    }

    BulkActionsListController.$inject = ['bulkActionsService'];
    function BulkActionsListController (bulkActionsService) {
        var vm = this;
        var defaultId = 'default_bulk_action_id';

        vm.listShowing = false;

        init();

        return;

        function init () {
            bulkActionsService.addInstance(vm.bulkActionsId || defaultId);
        }
    }
}());
