(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('onSave', onSave);

    onSave.$inject = ['$document'];

    function onSave ($document) {
        return {
            restrict: 'A',
            scope: {
                onSave: '&',
                saveDisabled: '=?'
            },
            link: function onSaveDirective_linkFn (scope, element, attrs) {
                var uid = '_' + new Date().getTime() + '_' + Math.random().toString().slice(2);
                var eventName = 'keydown.onSaveDirective' + uid;

                element.css('display', 'none');

                // Listen for Command/Control + S
                $document.on(eventName, function (e) {
                    if (e.shiftKey) {
                        return;
                    }

                    if ((e.ctrlKey || e.metaKey) && e.which === 83) {
                        if (attrs.hasOwnProperty('saveDisabled') && scope.saveDisabled) {
                            return false;
                        }

                        scope.onSave();

                        return false;
                    }
                });

                scope.$on('$destroy', function () {
                    $document.off(eventName);
                });
            }
        };
    }
}());

