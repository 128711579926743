(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('chartConfigFactory', chartConfigFactory);

    chartConfigFactory.$inject = ['$log', 'chartTypes', 'ProductPriceLineChart', 'ProductPriceEditLineChart'];

    function chartConfigFactory ($log, chartTypes, ProductPriceLineChart, ProductPriceEditLineChart) {
        var service = {
            getConfig: getConfig
        };

        // Maps chartTypes to constructors
        var configMap = {};
        configMap[chartTypes.PRODUCT_PRICE__LINE] = ProductPriceLineChart;
        configMap[chartTypes.PRODUCT_PRICE_EDIT__LINE] = ProductPriceEditLineChart;

        return service;

        function getConfig (data, type) {
            if (!chartTypes.hasOwnProperty(type)) {
                $log.warn('Chart type ' + type + ' does not exist.');
                return;
            }

            if (!configMap.hasOwnProperty(type)) {
                $log.warn('Chart type ' + type + ' not mapped.');
                return;
            }

            return new configMap[type](data);
        }
    }
}());

