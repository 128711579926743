angular.module('collaterateApp').controller('ConfirmDeleteCtrl', [
    '$scope',
    'name',
    function ($scope, name) {
        $scope.name = name;
    }
])
    .controller('AlertCtrl', [
        '$scope',
        'message',
        function ($scope, message) {
            $scope.message = message;
        }
    ])
    .controller('SiteNavigationController', [
        '$scope',
        '$timeout',
        'growlNotifications',
        'UtilService',
        'SiteService',
        'SiteFavoriteService',
        function ($scope, $timeout, growlNotifications, UtilService, SiteService, SiteFavoriteService) {
            var enabled = false;

            $scope.filter = {
                name: null
            };
            $scope.searching = false;
            $scope.availableSites = [];
            $scope.favoriteSites = [];

            $scope.chooseSite = function (siteId) {
                tNavController.chooseSite(siteId);
            };
            $scope.refresh = function () {
                initSiteData();
            };
            $scope.createFavorite = function (site, reload) {
                var siteId = site ? site.id : site.id;
                SiteFavoriteService.createSiteFavorite(site._embedded.supplier.id, siteId, $scope.adminUserId).then(function (response) {
                    // if (reload) {
                    $scope.loadFavoriteSites($scope.adminUserId);

                    // }
                });
            };
            $scope.deleteFavorite = function (site) {
                var favoriteId = null;

                // locate the favorite object for the site
                var index = 0;
                var foundIndex = null;
                angular.forEach($scope.adminUserSiteFavs, function (favorite) {
                    var siteId = favorite._embedded.site.id;
                    if (site.id == siteId) {
                        favoriteId = favorite.id;
                        foundIndex = index;
                    }
                    index++;
                });
                if (favoriteId) {
                    $scope.adminUserSiteFavs.splice(foundIndex, 1);
                    SiteFavoriteService.deleteSiteFavorite(favoriteId).then(function (response) {
                    // nothing to do
                    });
                }
            };
            $scope.isFavorite = function (site) {
                var value = false;
                angular.forEach($scope.adminUserSiteFavs, function (favorite) {
                    if (favorite._embedded.site.id == site.id) {
                        value = true;
                    }
                });

                return value;
            };

            $scope.loadFavoriteSites = function () {
                var favFilter = {
                    adminUserId: $scope.adminUserId
                };
                SiteFavoriteService.browseSiteFavorites(favFilter, 1, 200, 'site.name', 'ASC').then(function (response) {
                    $scope.adminUserSiteFavs = UtilService.unpackListResponse(response, 'adminUserSiteFavs');
                    $scope.favoriteSites = [];
                    angular.forEach($scope.adminUserSiteFavs, function (favorite) {
                        $scope.favoriteSites.push(favorite._embedded.site);
                        bindSiteSearchEvents();
                    });
                });
            };

            var initSiteData = function () {
                $scope.loadFavoriteSites();
                bindSiteSearchEvents();
            };

            var bindSiteSearchEvents = function () {
                var $input = $('#siteSearchInput');
                var $siteList = $('#site-list');
                var KEYS = {
                    tab: 9,
                    enter: 13,
                    down: 40,
                    up: 38
                };

                if (!$input.length) {
                    return;
                }

                if (enabled) {
                    return;
                }

                enable();

                function enable () {
                    $input.on('focus.sitesearch', handleInputFocus);
                    $input.on('keypress.sitesearch', handeInputKeypress);
                    $input.on('keydown.sitesearch', handleInputKeydown);
                    $siteList.on('keydown.sitesearch', '.siteListing', handleSiteListKeydown);

                    $scope.$on('$destroy', destroy);

                    enabled = true;
                }

                function destroy () {
                    $input.off('focus.sitesearch', handleInputFocus);
                    $input.off('keypress.sitesearch', handeInputKeypress);
                    $input.off('keydown.sitesearch', handleInputKeydown);
                    $siteList.off('keydown.sitesearch', handleSiteListKeydown);

                    enabled = false;
                }

                function handleInputFocus (e) {
                    $scope.$apply();
                    $('#recent-sites').fadeIn('fast');

                    $(document).on('mouseup.sitesearch', handleDocumentMouseup);

                    function handleDocumentMouseup (e) {
                        if (!$('#site-navigator').is(e.target) && $('#site-navigator').has(e.target).length === 0) {
                            $('#recent-sites').fadeOut('fast');
                            $('#all-sites').fadeOut('fast');
                            $input.val('');
                            $(document).off('mouseup.sitesearch');
                        }
                    }
                }

                function handeInputKeypress (e) {
                    $('#recent-sites').hide();
                    $('#all-sites').show();
                }

                function handleInputKeydown (e) {
                    if (e.which == KEYS.down || e.which == KEYS.tab || e.which == KEYS.enter) {
                        $siteList.find('.siteListing:visible')
                            .first()
                            .focus();
                        e.preventDefault();
                    }
                }

                function handleSiteListKeydown (e) {
                    var $this = $(this);

                    if (e.which !== KEYS.down && e.which !== KEYS.tab && e.which !== KEYS.enter && e.which !== KEYS.up) {
                        return;
                    }

                    switch (e.keyCode) {
                        case KEYS.down:
                            handleDownArrow(e);
                            break;
                        case KEYS.up:
                            handleUpArrow(e);
                            break;
                        case KEYS.enter:
                            handleEnter(e);
                            break;
                    }

                    function handleDownArrow () {
                        var next = $this.next('.siteListing');
                        next = next.length ? next : $this
                            .parent()
                            .children('.siteListing')
                            .first();
                        next.focus();
                        e.preventDefault();
                        e.stopPropagation();
                    }

                    function handleUpArrow () {
                        var prev = $this.prev('.siteListing');
                        prev = prev.length ? prev : $this
                            .parent()
                            .children('.siteListing')
                            .last();
                        prev.focus();
                        e.preventDefault();
                        e.stopPropagation();
                    }

                    function handleEnter () {
                        $this.find('.siteListing-link').click();
                    }
                }
            };
            $scope.$watchGroup(['filter.name'], debounce(function () {
                $scope.loading = true;
                if ($scope.filter.name == null || $scope.filter.name == '') {
                // if in searching mode, when the user clears the field trigger redisplay of favorites.
                    if ($scope.searching) {
                        $timeout(function () {
                            $('#siteSearchInput').trigger('focus');
                        }, 50);
                    }
                    $scope.availableSites = [];
                    $scope.searching = false;
                    $scope.loading = false;
                    bindSiteSearchEvents();
                } else {
                // only search on 2 or more characters
                    if ($scope.filter.name.length > 1) {
                        $scope.searching = true;
                        SiteService.browseSites(1, 9999, 'name', true, null, $scope.filter.name, true).then(function (response) {
                            $scope.availableSites = UtilService.unpackListResponse(response, 'sites');
                            $scope.loading = false;
                            bindSiteSearchEvents();
                        });
                    }
                }
            }, 300));

            $scope.$on('favoriteSitesChange', function (event, data) {
	    $scope.loadFavoriteSites();
            });
        }
    ])
    .controller('NotificationCtrl', [
        '$scope',
        'growlNotifications',
        function ($scope, growlNotifications) {
            $scope.growlNotifications = growlNotifications;
            $scope.dismissAll = function (notification) {
                growlNotifications.removeAll();
            };
        }
    ])
    .controller('BarcodeListenerCtrl', [
        '$scope',
        function ($scope) {
            var waitForMoreTypingInterval = 250;
            var doneTypingTimeout;
            var characters = [];

            // on keyup, start the countdown.  if nothing has been typed within the 'waitForMoreTypingInterval', fire the doneTyping function.
            $(window).keyup(function (event) {
                clearTimeout(doneTypingTimeout);

                // only add visible ascii chars to the buffer
                var keyCode = event.which;
                if (keyCode >= 33 && keyCode <= 126) {
                    characters.push(String.fromCharCode(keyCode));
                }

                // begin timer to wait for more keypresses to be added to the buffer
                doneTypingTimeout = setTimeout(doneTyping, waitForMoreTypingInterval);
            });

            // on keydown, clear the countdown
            $(window).keydown(function () {
                clearTimeout(doneTypingTimeout);
            });

            // user is "finished typing," do something
            function doneTyping () {
                if (characters.length >= 7) {
                    processBarcode(characters.join(''));
                }
                characters = [];
            }

            function processBarcode (barcode) {
                // var console = window.console || {
                //	log : new Function()
                // };

                // console.log('barcode scanned:', barcode);

                // Strip out any special characters that may have been improperly introduced by the scanner
                barcode = barcode.replace(/[^a-zA-Z0-9]/g, '');

                // build token list by breaking up the barcode into sections of digits and non-digits
                var tokens = barcode.match(/(\d+|[^\d]+)/g);

                // branch on first character of barcode
                if (tokens && tokens.length > 1) {
                    // console.log('tokens ', tokens);
                    if (tokens[0] == 'S') {
                        // order detail page - jump to shipment
                        window.location.replace('/siteadmin/orders/detail?PS=' + tokens[1]);
                    } else if (tokens[0] == 'O') {
                        if (tokens.length > 2 && tokens[2] == 'J') {
                            // order detail page - jump to job
                            window.location.replace('/siteadmin/orders/detail?ORDERNUMBER=' + tokens[1] + '&JOB=' + tokens[3]);
                        } else {
                            // order detail page
                            window.location.replace('/siteadmin/orders/detail?ORDERNUMBER=' + tokens[1]);
                        }
                    } else if (tokens[0] == 'P') {
                        // Job packing slip iframe
                        tNavController.renderHiddenPrintableIFrame('/siteadmin/printablePackingSlip', tokens[1]);
                    } else if (tokens[0] == 'J') {
                        // order detail page - jump to job, first seven digits to avoid the '-1' of reworks
                        window.location.replace('/siteadmin/orders/detail?JOB=' + tokens[1].substring(0, 7));
                    }
                }
            }
        }
    ]);
