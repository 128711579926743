(function () {
    'use strict';

    angular.module('collaterateApp').controller('ProductionTaskSpecificationsViewController', ProductionTaskSpecificationsViewController);

    ProductionTaskSpecificationsViewController.$inject = ['$rootScope', '$scope', '$uibModal', 'UtilService', 'ProductionTaskSpecificationService'];

    function ProductionTaskSpecificationsViewController ($rootScope, $scope, $uibModal, UtilService, ProductionTaskSpecificationService) {
        var ctrl = this;

        ctrl.isNew = true;
        ctrl.supplierId = $scope.supplierId;
        ctrl.operationId = $scope.operationId;
        ctrl.operationItemId = $scope.operationItemId;
        ctrl.format = $scope.format;
        ctrl.taskSpecifications = [];

        ctrl.edit = edit;

        init();

        function init () {
            ProductionTaskSpecificationService.getTaskSpecificationsForOperationItemId(ctrl.supplierId, ctrl.operationItemId, ctrl.format).then(function (response) {
                ctrl.taskSpecifications = response.data.content;
            });
        }

        function edit (operationId, operationItemId) {
            var modal = $uibModal.open({
                templateUrl: '/templates/production-task-specifications-edit.template.html',
                windowClass: 'med-modal',
                backdrop: 'static',
                controller: 'ProductionTaskSpecificationsEditController',
                controllerAs: 'productionTaskSpecEditCtrl',
                resolve: {
                    supplierId: function () {
                        return ctrl.supplierId;
                    },
                    operationId: function () {
                        return operationId;
                    },
                    operationItemId: function () {
                        return operationItemId == '' || operationItemId == null ? null : operationItemId;
                    },
                    format: function () {
                        return ctrl.format;
                    }
                }
            });

            modal.result.then(function () {
                init();
            });
        }
    }
}());
