/*
 * Copyright 2025 Collaterate. All rights reserved.
 */

(function () {
    'use strict';

    var app = angular.module('collaterateApp', [
        'ngSanitize',
        'ngAnimate',
        'ngAria',
        'highcharts-ng',
        'ui.bootstrap',
        'ui.router',
        'ui.router.title',
        'ui.sortable',
        'growlNotifications',
        'fcsa-number',
        'angularMoment',
        'ngMessages',
        'ioHelpers',
        'templates',
        '$q-spread',
        'collaterate.common',
        'collaterate.common.directives.autocomplete',
        'collaterate.common.directives.simpletree',
        'collaterate.common.directives.currency',
        'ui.tinymce',
        'collaterate.common.plupload',
        'formly',
        'ui.validate',
        'tbg.errorReporting',
        'collaterate.interceptors'
    ]);

    // declare templates module, to be primed via gulp
    angular.module('templates', []);

    var restRootUrl = null;
    var restUserType = '';
    if (USER_TYPE == 'COLLATERATE_ADMIN') {
        restRootUrl = '/rest/internal/collaterateAdmin';
    } else if (USER_TYPE == 'SUPPLIER_ADMIN') {
        restRootUrl = '/rest/internal/supplierAdmin';
        restUserType = 'admin';
    } else if (USER_TYPE == 'SITE_ADMIN') {
        restRootUrl = '/rest/internal/siteAdmin';
        restUserType = 'storefront';
    }
    var restUserId = -1;
    if (typeof USER_ID != 'undefined') {
        restUserId = USER_ID;
    }

    app.value('restUser', {
        id: restUserId,
        type: restUserType,
        isSiteAdminOnly: restUserType === 'storefront',
        isAdminOnly: restUserType === 'admin'
    });

    if (restRootUrl == null) {
        throw new Error('ERROR:  The USER_TYPE global variable has not been set correctly. The rest root url cannot be determined');
    }

    app.value('UserTypes', {
        STOREFRONT: 'storefront',
        ADMIN: 'admin'
    });

    app.value('restRootUrl', restRootUrl);
    app.value('restNodeProxyUrl', '/rest/internal/v1/node/secure');

    app.config(config);
    config.$inject = ['$locationProvider', '$uibTooltipProvider', '$compileProvider', 'PluploadSettingsProvider', 'CollaterateSecurityServiceProvider', 'formlyConfigProvider'];
    function config ($locationProvider, $uibTooltipProvider, $compileProvider, PluploadSettingsProvider, CollaterateSecurityServiceProvider, formlyConfigProvider) {
        CollaterateSecurityServiceProvider.setRootUrl(restRootUrl);

        $locationProvider.html5Mode({
            requireBase: false,
            enabled: true,
            rewriteLinks: false
        }).hashPrefix('!');

        PluploadSettingsProvider.options({
            url: restRootUrl + '/upload/plupload',
            silverlight_xap_url: '/web3/build/base/js/plupload/js/plupload.silverlight.xap',
            multipart_params: {},
            filters: {
                max_file_size: '5gb'
            },
            chunk_size: '1mb'
        });

        $uibTooltipProvider.options({
            animation: false,
            popupDelay: 0
        });

        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|javascript):/);

        formlyConfigProvider.setWrapper([
            {
            // templateUrl: '/web3/path/to/template.html'
                name: 'formGroupWrapper',
                template: ['<div class="form-group" ng-class="{\'has-error\': form[id].$invalid && form.$submitted }">', '<label for="{{::id}}" class="control-label">{{::options.templateOptions.label}}</label>', '<formly-transclude></formly-transclude>', '<div ng-messages="form[id].$error" ng-show="form.$submitted" class="field-error"><div ng-message="required">"{{::options.key}}" is required.</div></div>', '</div>'].join(' ')
            }
        ]);

        formlyConfigProvider.setType({
            name: 'input',
            template: '<input class="form-control" ng-model="model[options.key]" ng-required="options.templateOptions.required" />',
            wrapper: 'formGroupWrapper'
        });

        formlyConfigProvider.setType({
            name: 'select',
            template: '<select ng-model="model[options.key]" ng-options="option.value as option.name group by option.group for option in options.templateOptions.options" ng-required="options.templateOptions.required"></select>',
            wrapper: 'formGroupWrapper'
        });

        // TODO: once other admin tinymce areas have been updates, implement for textarea input type

        formlyConfigProvider.setType({
            name: 'textarea',
            template: '<textarea class="form-control" xx-ui-tinymce ng-model="model[options.key]" ng-required="options.templateOptions.required"></textarea>',
            wrapper: 'formGroupWrapper'
        });
    }

    app.run(run);
    run.$inject = ['uiTinymceConfig', '$http', 'ioHelpers', 'CollaterateSecurityService', 'restRootUrl'];
    function run (uiTinymceConfig, $http, ioHelpers, CollaterateSecurityService, restRootUrl) {
        configureTinyMCE();
        configureSecurityService();
        disableResourceLinks();

        function configureTinyMCE () {
            /*
                Sets global defaults for tinyMCE editor. To override or add to these on a per-instance basis,
                create a configuration object in your controller:

                vm.editorOptions = {
                    elementpath: true,
                    menubar: 'edit format'
                }

                Then, assign that config as a value to the ui-tinymce directive in your markup:

                <textarea ui-tinymce="vm.editorOptions"></textarea>

                See more at https://github.com/angular-ui/ui-tinymce
            */
            uiTinymceConfig.baseURL = '/web3/build/base/js/tinymce';
            uiTinymceConfig.elementpath = false;
            uiTinymceConfig.menubar = 'edit view format';
        }

        function configureSecurityService () {
            CollaterateSecurityService.setUser({
                restUserId: restUserId,
                restUserType: restUserType
            });
        }

        // Set header on all get rest requests to turn off resource links
        function disableResourceLinks () {
            var hideResourceLinks = 'false';
            if (typeof HIDE_RESOURCE_LINKS != 'undefined') {
                hideResourceLinks = HIDE_RESOURCE_LINKS;
            }
            $http.defaults.headers.get = {
                'collaterate-hide-resource-links': hideResourceLinks
            };
            $http.ioHelpers = ioHelpers;
        }
    }

    app.factory('debounce', [
        '$timeout',
        function ($timeout) {
            return function (callback, interval) {
                var timeout = null;
                return function () {
                    $timeout.cancel(timeout);
                    timeout = $timeout(callback, interval);
                };
            };
        }
    ]);

    // Directives

    /**
	 * This directive allows you to set validation based whether one field value matches another
	 */
    var tbgMatches = function () {
        return {
            require: 'ngModel',
            scope: {
                otherModelValue: '=tbgMatches'
            },
            link: function (scope, element, attributes, ngModel) {
                ngModel.$validators.matches = function (modelValue) {
                    return modelValue == scope.otherModelValue;
                };

                scope.$watch('otherModelValue', function () {
                    ngModel.$validate();
                });
            }
        };
    };

    app.directive('tbgMatches', tbgMatches);

    /**
     * This directive allows you to validate a credit card number
     */
    var validCcNumber = function () {
        return {
            require: 'ngModel',
            scope: {
                ccType: '=validCcNumber'
            },
            link: function (scope, element, attributes, ngModel) {
                ngModel.$validators.validateNumberByCcType = function (modelValue) {
                    return isValidCreditCardNumber(modelValue, scope.ccType ? scope.ccType.name : '');
                };

                scope.$watch('ccType', function () {
                    ngModel.$validate();
                });
            },

            isValidCreditCardNumber: function (cardNumber, cardTypeName) {
                var isValid = false;
                var ccCheckRegExp = /[^\d ]/;
                isValid = !ccCheckRegExp.test(cardNumber);
                var cardNumbersOnly = null;
                var cardNumberLength = 0;

                if (isValid) {
                    cardNumbersOnly = cardNumber.replace(/ /g, '');
                    cardNumberLength = cardNumbersOnly.length;
                    var lengthIsValid = false;
                    var prefixIsValid = false;
                    var prefixRegExp;

                    // NOTE: these need to match the name values in ORDER_CC_TYPES
                    switch (cardType) {
                        case 'Visa':
                            lengthIsValid = (cardNumberLength == 16 || cardNumberLength == 13);
                            prefixRegExp = /^4/;
                            break;

                        case 'Mastercard':
                            lengthIsValid = (cardNumberLength == 16);
                            prefixRegExp = /^5[1-5]/;
                            break;

                        case 'American Express':
                            lengthIsValid = (cardNumberLength == 15);
                            prefixRegExp = /^3(4|7)/;
                            break;

                        case 'Discover':
                            lengthIsValid = (cardNumberLength == 16);
                            prefixRegExp = /^6011/;
                            break;

                        default:
                            prefixRegExp = /^$/;
                            return false;
                    }// switch

                    prefixIsValid = prefixRegExp.test(cardNumbersOnly);
                    isValid = prefixIsValid && lengthIsValid;
                }
                if (isValid) {
                    var numberProduct;
                    var checkSumTotal = 0;

                    for (var digitCounter = cardNumberLength - 1; digitCounter >= 0; digitCounter--) {
                        checkSumTotal += parseInt(cardNumbersOnly.charAt(digitCounter));
                        digitCounter--;
                        numberProduct = String(cardNumbersOnly.charAt(digitCounter) * 2);
                        for (var productDigitCounter = 0; productDigitCounter < numberProduct.length; productDigitCounter++) {
                            checkSumTotal += parseInt(numberProduct.charAt(productDigitCounter));
                        }
                    }

                    isValid = (checkSumTotal % 10 == 0);
                }

                return isValid;
            }
        };
    };

    app.directive('validCcNumber', validCcNumber);

    // Currently AngularJs required on an input of type file isn't working. This validator directive fixes that
    var validFile = function () {
        return {
            require: 'ngModel',
            link: function (scope, el, attrs, ngModel) {
                // change event is fired when file is selected
                el.bind('change', function () {
                    scope.$apply(function () {
                        ngModel.$setViewValue(el.val());
                        ngModel.$render();
                    });
                });
            }
        };
    };
    app.directive('validFile', validFile);
}());
