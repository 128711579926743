(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .directive('ignoreDirty', ignoreDirty);

    ignoreDirty.$inject = [];

    function ignoreDirty () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, el, attrs, ngModelController) {
                ngModelController.$setPristine = angular.noop;
                ngModelController.$pristine = false;
            }
        };
    }
}());
