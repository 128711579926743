angular.module('collaterateApp').directive('costTableView', function () {
    return {
        scope: {
            costTableId: '@',
            hiddenInputName: '@'
        },
        templateUrl: '/templates/cost-table-view.template.html'
    };
});

angular.module('collaterateApp').directive('keepFocus', [
    '$timeout',
    function ($timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, $element, attrs, ngModel) {
                ngModel.$parsers.unshift(function (value) {
                    $timeout(function () {
                        $element[0].focus();
                    });
                    return value;
                });
            }
        };
    }
]);
