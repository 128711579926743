(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('sessionStorageService', sessionStorageService);

    sessionStorageService.$inject = ['$window', '$log', 'sessionStorageKeys'];

    function sessionStorageService ($window, $log, sessionStorageKeys) {
        return {
            get: getStorageItem,
            set: setStorageItem,
            keys: sessionStorageKeys
        };

        function getStorageItem (key) {
            var storageObject;

            if (!sessionStorageKeys[key]) {
                $log.warn('Session storage key "' + key + '" not found in sessionStorageKeys service. It is recommended to add all required sessionStorage keys to this service.');
            }

            storageObject = $window.sessionStorage.getItem(key);

            if (storageObject && storageObject !== 'undefined') {
                return JSON.parse(storageObject);
            }

            return null;
        }

        function setStorageItem (key, data) {
            if (!sessionStorageKeys[key]) {
                $log.warn('Session storage key "' + key + '" not found in sessionStorageKeys service. It is recommended to add all required sessionStorage keys to this service.');
            }

            if (typeof data === 'undefined') {
                $window.sessionStorage.removeItem(key);
                return;
            }

            $window.sessionStorage.setItem(key, JSON.stringify(data));
        }
    }
}());
