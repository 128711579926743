(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('iconCheck', iconCheck);

    function iconCheck () {
        return {
            restrict: 'AE',
            replace: true,
            template: '<svg class="svg"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#info-success-icon"></use></svg>'
        };
    }
}());
