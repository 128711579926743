(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('followedUsers', followedUsers);

    followedUsers.$inject = ['$timeout'];

    function followedUsers ($timeout) {
        var service = {
            getList: getList,
            getUserIdsCSVList: getUserIdsCSVList,
            addUser: addUser,
            removeUser: removeUser
        };

        return service;

        function getList (csrUserId) {
            return $timeout(function () {
                return formatNameForWatchedUsers(JSON.parse(localStorage.getItem('userList ' + csrUserId)) || []);
            });

            function formatNameForWatchedUsers (watchedUsers) {
                for (var i = 0; i < watchedUsers.length; i++) {
                    var fullName = watchedUsers[i].fullName;
                    var someName = fullName;
                    var spaceBetweenFirstAndLastName = someName.indexOf(' ');
                    var firstName = someName.substring(0, 1);
                    firstName += '. ';
                    var lastName = someName.substring(spaceBetweenFirstAndLastName + 1);
                    fullName = firstName + lastName;

                    watchedUsers[i].fullName = fullName;
                }

                return watchedUsers;
            }
        }

        function getUserIdsCSVList (csrUserId) {
            return getList(csrUserId)
                .then(createCSV);

            function createCSV (userList) {
                var userIdsCSVList = csrUserId + ',';

                // loop through each JSON user object and append the csrUserId to the list
                if (userList != null) {
                    for (var i = 0; i < userList.length; i++) {
                        var obj = userList[i];
                        for (var key in obj) {
                            if (key == 'id') {
                                userIdsCSVList += obj[key] + ',';
                            }
                        }
                    }
                }

                // strip out the trailing comma
                return userIdsCSVList.replace(/,(\s+)?$/, '');
            }
        }

        function addUser (csrUserId, user) {
            return getList(csrUserId)
                .then(addUserToList)
                .then(save);

            function addUserToList (list) {
                list.push(user);
                return list;
            }

            function save (list) {
                return saveList(csrUserId, list);
            }
        }

        function removeUser (csrUserId, index) {
            return getList(csrUserId)
                .then(removeUserFromList)
                .then(save);

            function removeUserFromList (list) {
                list.splice(index, 1);
                return list;
            }

            function save (list) {
                return saveList(csrUserId, list);
            }
        }

        function saveList (csrUserId, list) {
            return $timeout(function () {
                localStorage.setItem('userList ' + csrUserId, JSON.stringify(list));
                return getList(csrUserId);
            });
        }
    }
}());
