(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('chart', chart);

    function chart () {
        return {
            restrict: 'EA',
            template: '<highchart config="vm.chart.config"></highchart>',
            scope: {
                chartData: '=',
                chartType: '='
            },
            controller: ChartController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    ChartController.$inject = ['$scope', 'chartConfigFactory'];

    function ChartController ($scope, chartConfigFactory) {
        var vm = this;

        $scope.$watch('vm.chartData', refreshChart, true);
        $scope.$watch('vm.chartType', handleTypeChange);

        initChart();

        return;

        function initChart () {
            vm.chart = chartConfigFactory.getConfig(vm.chartData, vm.chartType);
        }

        function refreshChart (newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            vm.chart.update(vm.chartData);
        }

        function handleTypeChange (newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            initChart();
        }
    }
}());

