(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('InternalNotificationService', InternalNotificationService);

    InternalNotificationService.$inject = ['$http', '$q', 'restRootUrl'];

    function InternalNotificationService ($http, $q, restRootUrl) {
        var service = {
            getNotificationsForRecipientIds: getNotificationsForRecipientIds,
            getNotificationCountForRecipientIds: getNotificationCountForRecipientIds,
            dismissNotification: dismissNotification,
            reopenNotification: reopenNotification
        };

        return service;

        // accepts an array of admin user IDs
        function getNotificationsForRecipientIds (recipientIds) {
            var requestConfig = {
                headers: {
                    'collaterate-hide-resource-links': 'true'
                },
                url: restRootUrl + '/internalNotification/recipients/' + recipientIds,
                method: 'GET',
                params: {
                    page: 0,
                    size: 2000,
                    sort: 'createdOn,DESC'
                }
            };

            return $http(requestConfig)
                .catch(handleError);

            function handleError (response) {
                return $q.reject('Unexpected error retrieving internal notifications.');
            }
        }

        // accepts an array of admin user IDs
        function getNotificationCountForRecipientIds (recipientIds) {
            var requestConfig = {
                url: restRootUrl + '/internalNotification/recipients/' + recipientIds + '/count?excludeGracePeriod=true',
                method: 'GET'
            };

            return $http(requestConfig)
                .catch(handleError);

            function handleError (response) {
                return $q.reject('Unexpected error retrieving internal notification count.');
            }
        }

        function dismissNotification (notification) {
            var requestConfig = {
                url: restRootUrl + '/internalNotification/' + notification.id + '/dismiss',
                method: 'PATCH'
            };

            return $http(requestConfig)
                .catch(handleError);

            function handleError (response) {
                return $q.reject('Unexpected error dismissing the internal notification.');
            }
        }

        function reopenNotification (notification) {
            var requestConfig = {
                url: restRootUrl + '/internalNotification/' + notification.id + '/reopen',
                method: 'PATCH'
            };

            return $http(requestConfig)
                .catch(handleError);

            function handleError (response) {
                return $q.reject('Unexpected error reopening the internal notification.');
            }
        }
    }
}());
