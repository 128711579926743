(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .factory('apiV2', apiV2);

    apiV2.$inject = ['$http', 'restRootUrl', '$q', 'growlNotifications', 'serializeParams'];

    function apiV2 ($http, restRootUrl, $q, growlNotifications, serializeParams) {
        return function (requestConfig) {
            var config = angular.copy(requestConfig);
            config.url = restRootUrl + config.url;

            if (config.hasOwnProperty('params')) {
                config.url += serializeParams(config.params);
                delete config.params;
            }

            return $http(config)
                .then(handleApiMessage)
                .then(handleApiDownload)
                .then(handleApiResponse)
                .catch(handleApiError);
        };

        function handleApiMessage (response) {
            if (response && response.data && response.data.messages) {
                response.data.messages.forEach(function (message) {
                    growlNotifications.add(message.detail, message.type.toLowerCase());
                });
            }

            return response;
        }

        /*
            Trigger file download from API response functionality

            To use, set `requestConfig.download` to `true`.
            The file type will be set to whatever the `requestConfig.headers.Accept` header is set to. (defaults to "application/json")
            The filename of the download is set via `requestConfig.downloadFilename`. This should include the file extension. (defaults to "collaterate-download.txt")
         */
        function handleApiDownload (response) {
            if (response && response.config && response.config.download) {
                var blob = new Blob([response.data], { type: response.config.headers.Accept || response.config.headers.accept || 'application/json' });
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = response.config.downloadFilename || 'collaterate-download.txt';
                a.style = 'display: none';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(a.href);
                document.body.removeChild(a);
            }

            return response;
        }

        function handleApiResponse (response) {
            if (response && response.data && response.data.page) {
                return {
                    page: response.data.page,
                    content: response.data.content
                };
            }

            return response && response.data && response.data.hasOwnProperty('content')
                ? response.data.content
                : response.hasOwnProperty('data')
                ? response.data
                : response;
        }

        function handleApiError (e) {
            var error = e;

            // TODO: Implement Input and Response error types...
            // if (e.status === 400 && e.data.errors && e.data.errors[0].source) {
            //     // error = new InputError(e.data)
            // } else if (e.data.errors) {
            //     // error = new ResponseError(e.data.errors[0]);
            //     growlNotifications.add(e.data.errors[0].detail, 'warning');
            // }

            if (e.data && e.data.errors) {
                e.data.errors.forEach(function (e) {
                    growlNotifications.add(e.detail, 'warning');
                });
            }

            return $q.reject(error);
        }
    }
}());
