/*
    Use:
    <input phone ng-model="someModel" />

    Adds `isValidNumber` validator to ngModelController
*/

(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .directive('phone', phone);

    phone.$inject = ['$window', '$timeout'];

    function phone ($window, $timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {},
            link: function phoneDirectiveLinkFn (scope, element, attrs, ngModelController) {
                if (!$window.intlTelInputGlobals || !$window.forms) {
                    return;
                }

                init();

                return;

                function init () {
                    initializePlugin();
                    setInputAttributes();
                    enableEventHandlers();
                    setUpValidators();
                    setUpParsers();
                }

                function initializePlugin () {
                    $timeout(function () {
                        $window.forms.phone(element);
                    });
                }

                function setInputAttributes () {
                    element.attr('type', 'tel');
                    $timeout(function () {
                        element.attr('autocomplete', 'nope');
                    });
                }

                function enableEventHandlers () {
                    element.on('countrychange', handleCountryChange);
                    scope.$on('$destroy', disableEventHandlers);
                }

                function disableEventHandlers () {
                    element.off('countrychange');
                    element.intlTelInput('destroy');
                }

                function setUpValidators () {
                    ngModelController.$validators.isValidNumber = function (modelValue, viewValue) {
                        if (!element.prop('required') && !viewValue) {
                            return true;
                        }
                        return element.intlTelInput('isValidNumber');
                    };
                }

                function setUpParsers () {
                    ngModelController.$parsers.push(function (value) {
                        return element.intlTelInput('getNumber');
                    });
                }

                function handleCountryChange () {
                    $timeout(function () {
                        var number = element.intlTelInput('getNumber');

                        element.intlTelInput('setNumber', number);
                        ngModelController.$setViewValue(number);
                        ngModelController.$validate();
                    });
                }
            }
        };
    }
}());
