(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('moneyUtils', moneyUtils);

    moneyUtils.$inject = [];

    function moneyUtils () {
        var service = {
            moneyStringToFloat: moneyStringToFloat,
            moneyFloatToString: moneyFloatToString
        };

        return service;

        function moneyStringToFloat (string) {
            var cleanValue = string.replace(/[^0-9.]/g, '');
            var fraction = cleanValue.split('.')[1];
            var decimals = fraction && fraction.length || 0;

            return Number(parseFloat(cleanValue).toFixed(decimals >= 2 ? 2 : decimals));
        }

        function moneyFloatToString (money) {
            return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }
}());
