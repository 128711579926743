(function () {
    'use strict';

    // This directive has a dependency on jQuery

    /*
	    <multichooser
	        list-excluded="excluded"             // Array of items populating the excluded (left) list
	        list-included="included"             // Array of items populating the included (right) list
	        on-exclude="onExclude(items)"        // Callback fired when items are excluded, passes array of item objects to be excluded
	        on-include="onInclude(items)"        // Callback fired when items are included, passes array of item objects to be included
	        display-prop="name"                  // The object property to display in the lists
	        active-prop="active"                 // (Optional) The object property that determines "active" state. Omitting this attribute hides the filtering checkbox.
	        label-inactive="Show Inactive Label" // (Optional) Label for the "show inactive" checkbox. Hidden if "active-prop" attribute not set.
	        label-excluded="Excluded Label"      // (Optional) Label on the left list, defaults to "Excluded"
	        label-included="Included Label"      // (Optional) Label on the right list, defaults to "Included"
	    ></multichooser>
	*/

    angular
        .module('collaterateApp')
        .directive('multichooser', multichooser);

    multichooser.$inject = [];

    function multichooser () {
        return {
            restrict: 'AE',
            templateUrl: '/templates/multichooser.template.html',
            link: function (scope, element, attrs) {
                scope.labelExcluded = attrs.labelExcluded || 'Excluded';
                scope.labelIncluded = attrs.labelIncluded || 'Included';
                scope.labelInactive = attrs.labelInactive || 'Show Inactive';
                scope.activeFiltering = !!attrs.activeProp;

                scope.filter = function (q) {
                    var filter = {};
                    filter[attrs.displayProp] = q;
                    return filter;
                };

                scope.resetListState = function (listname) {
                    $(element).find('.bucketlist-panel-bd-content-list__' + listname + ' li.isActive')
                        .each(function () {
                            $(this).scope().active = false;
                        });
                };
            },
            scope: {
                listIncluded: '=',
                listExcluded: '=',
                onInclude: '&',
                onExclude: '&',
                displayProp: '@',
                activeProp: '@'
            }
        };
    }
}());
