(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('fulfillmentCentersService', fulfillmentCentersService);

    fulfillmentCentersService.$inject = ['api', '$q'];

    function fulfillmentCentersService (api, $q) {
        var service = {
            getAll: getAll,
            getForSite: getForSite
        };

        return service;

        function getAll () {
            var requestConfig = {
                url: '/fulfillmentCenters',
                method: 'GET',
                params: {
                    size: 2000,
                    sort: 'name,ASC',
                    projection: 'summary'
                }
            };

            return api(requestConfig, null, 'fulfillmentCenters')
                .catch(handleGetAllError);

            function handleGetAllError (e) {
                return $q.reject('Unexpected error fetching fulfillment centers.');
            }
        }

        function getForSite (siteId) {
            var requestConfig = {
                url: '/fulfillmentCenters/findBySiteId/' + siteId,
                method: 'GET',
                params: {
                    size: 2000,
                    sort: 'name,ASC',
                    projection: 'summary'
                }
            };

            return api(requestConfig)
                .catch(handleGetForSiteError);

            function handleGetForSiteError (e) {
                return $q.reject('Unexpected error finding fulfillment centers for site.');
            }
        }
    }
}());
