(function () {
    'use strict';

    angular.module('collaterateApp').service('UtilService', UtilService);
    UtilService.$inject = ['growlNotifications'];

    function UtilService (growlNotifications) {
        var _service = {};

        // methods
        _service.unpackListResponse = unpackListResponse;
        _service.generateInstances = generateInstances;
        _service.replaceArray = replaceArray;
        _service.clearObject = clearObject;
        _service.replaceObject = replaceObject;

        // functions
        function unpackListResponse (response, listName) {
            var data = response.data ? response.data : response;
            var list = [];

            if (data._embedded) {
                if (data._embedded[listName]) {
                    list = data._embedded[listName];
                } else {
                    console.error('Could not find embedded entity with the name "' + listName + '"');
                }
            } else {
                console.error('No embedded data was returned in the response.');
            }
            return list;
        }

        function generateInstances (src, InstanceConstructor) {
            var ret = [];

            if (src && src.length) {
                for (var i = 0; i < src.length; i++) {
                    ret.push(new InstanceConstructor(src[i]));
                }
            }

            return ret;
        }

        function replaceArray (array, contents) {
            // Work off of copy, prevents case where "array" and "contents"
            // are references to the same array, the array.splice(0)
            // would empty both before merge, with the final
            // result being an empty array.
            var contentsCopy = angular.copy(contents);

            // Empty the target array
            array.splice(0);

            // Merge the passed contents into the target array
            Array.prototype.push.apply(array, contentsCopy);

            // Even though target array was altered in place, return target array
            return array;
        }

        function clearObject (obj) {
            for (var prop in obj) {
                delete obj[prop];
            }

            return obj;
        }

        function replaceObject (oldObject, newObject) {
            clearObject(oldObject);
            angular.merge(oldObject, newObject);
            return oldObject;
        }

        return _service;
    }

    angular.module('collaterateApp').service('SiteService', SiteService);
    SiteService.$inject = ['$http', 'growlNotifications', 'restRootUrl', '$q', 'api'];

    function SiteService ($http, growlNotifications, restRootUrl, $q, api) {
        return {
            findById: function (siteId, params) {
                var endpoint = restRootUrl + '/sites/' + siteId;

                var requestConfig = {
                    url: endpoint,
                    method: 'GET'
                };

                if (params) {
                    requestConfig.params = params;
                }

                return $http(requestConfig)
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (response) {
                        return $q.reject('An error occurred finding site by id ' + siteId);
                    });
            },
            getAllSites: function () {
                var requestConfig = {
                    method: 'GET',
                    url: '/sites/all'
                };

                return api(requestConfig, null, 'sites')
                    .catch(handleRequestError);

                function handleRequestError () {
                    return $q.reject('There was an error getting all sites.');
                }
            },
            getAllSitesForCustomQuotes: function () {
                var requestConfig = {
                    url: '/sites/search',
                    method: 'GET',
                    params: {
                        page: 0,
                        size: 2000,
                        sort: 'name,ASC',
                        embeddedAssociations: ['siteStorefrontConfigurations'],
                        q: {
                            allowsCustomQuote: true
                        }
                    }
                };

                return api(requestConfig, Site, 'sites')
                    .catch(handleRequestError);

                function handleRequestError () {
                    return $q.reject('There was an error getting all sites for custom quotes.');
                }

                function Site (data) {
                    this.id = data.id;
                    this.name = data.name;
                    this.allowAnonymousCustomQuote = data._embedded.siteStorefrontConfigurations[0].allowAnonymousCustomQuote;
                    this.allowUserAssignedCustomQuote = data._embedded.siteStorefrontConfigurations[0].allowUserAssignedCustomQuote;
                }
            },
            browseSites: function (pageNumber, pageSize, sort, ascending, adminUserId, name, active) {
                var ascDesc = ascending ? 'ASC' : 'DESC';
                var q = '';
                if (adminUserId != null && adminUserId != '') {
                    q += 'adminUserId=' + adminUserId;
                }
                if (name != null) {
                    q += ':name=' + name;
                }
                q += ':active=' + active;

                return $http({
                    method: 'GET',
                    url: restRootUrl + '/sites/search',
                    params: {
                        page: pageNumber - 1,
                        size: pageSize,
                        sort: sort + ',' + ascDesc,
                        q: q,
                        projection: 'summary',
                        embeddedAssociations: 'supplier'
                    }
                }).error(function (data, status, headers, config) {
                    growlNotifications.add('Unexpected error browsing sites', 'warning');
                });
            },
            updateProductAttributesDefinition: function (siteId, definition) {
                var requestConfig = {
                    url: restRootUrl + '/sites/' + siteId + '/updateProductAttributesDefinition',
                    method: 'POST',
                    data: {
                        id: siteId,
                        attributesDefinition: definition
                    }
                };

                return $http(requestConfig)
                    .catch(function (response) {
                        return $q.reject('An unexpected error occurred attempting to save product attributes. ' + response.data.message + '.');
                    });
            },
            updateCategoryAttributesDefinition: function (siteId, definition) {
                var requestConfig = {
                    url: restRootUrl + '/sites/' + siteId + '/updateCategoryAttributesDefinition',
                    method: 'POST',
                    data: {
                        id: siteId,
                        attributesDefinition: definition
                    }
                };

                return $http(requestConfig)
                    .catch(function (response) {
                        return $q.reject('An unexpected error occurred attempting to save category attributes. ' + response.data.message + '.');
                    });
            },
            updateContentPageAttributesDefinition: function (siteId, definition) {
                var requestConfig = {
                    url: restRootUrl + '/sites/' + siteId + '/updateContentPageAttributesDefinition',
                    method: 'POST',
                    data: {
                        id: siteId,
                        attributesDefinition: definition
                    }
                };

                return $http(requestConfig)
                    .catch(function (response) {
                        return $q.reject('An unexpected error occurred attempting to save content page attributes. ' + response.data.message + '.');
                    });
            }
        };
    }

    angular.module('collaterateApp').service('DivisionService', DivisionService);
    DivisionService.$inject = ['$http', 'growlNotifications', 'restRootUrl'];

    function DivisionService ($http, growlNotifications, restRootUrl) {
        return {
            getAllDivisions: function () {
                return $http({
                    method: 'GET',
                    url: restRootUrl + '/divisions',
                    params: {
                        page: 0,
                        size: 99999,
                        sort: 'name,ASC'
                    }
                }).success(function (data, status, headers, config) {})
                    .error(function (data, status, headers, config) {
                        growlMessages.showErrorMessage('Unexpected error browsing for divisions.', 'warning');
                    });
            },
            getAllDivisionsForSiteId: function (siteId) {
                return $http({
                    method: 'GET',
                    url: restRootUrl + '/divisions/search/findBySiteId',
                    params: {
                        siteId: siteId,
                        page: 0,
                        size: 99999,
                        sort: 'name,ASC',
                        projection: 'SUMMARY',
                        embeddedAssociations: ['parent,children']
                    }
                }).success(function (data, status, headers, config) {})
                    .error(function (data, status, headers, config) {
                        growlMessages.showErrorMessage('Unexpected error browsing for divisions.', 'warning');
                    });
            },
            getAllDivisionsForSiteIdWithPaging: function (siteId, active, keyword, pageNumber, pageSize) {
                return $http({
                    method: 'GET',
                    url: restRootUrl + '/divisions/search/findBySiteId',
                    params: {
                        siteId: siteId,
                        active: active,
                        keyword: keyword || '',
                        page: pageNumber - 1,
                        size: pageSize,
                        sort: 'name,ASC',
                        projection: 'SUMMARY',
                        embeddedAssociations: ['parent,children']
                    }
                }).success(function (data, status, headers, config) {})
                    .error(function (data, status, headers, config) {
                        growlMessages.showErrorMessage('Unexpected error browsing for divisions.', 'warning');
                    });
            }
        };
    }

    angular.module('collaterateApp').service('ThemeService', ThemeService);

    ThemeService.$inject = ['$http', 'restRootUrl', '$q', 'UtilService'];

    function ThemeService ($http, restRootUrl, $q, UtilService) {
        var _service = {};

        // methods
        _service.getCategoryContentTemplates = getCategoryContentTemplates;
        _service.getContentPageContentTemplates = getContentPageContentTemplates;
        _service.getCategoryRootUrl = getCategoryRootUrl;
        _service.getContentPageRootUrl = getContentPageRootUrl;
        _service.getCategoryRootResourceUrl = getCategoryRootResourceUrl;
        _service.getContentPageRootResourceUrl = getContentPageRootResourceUrl;
        _service.convertToUrlSafeString = convertToUrlSafeString;

        // functions
        function getCategoryContentTemplates (siteId) {
            var endpoint = restRootUrl + '/themes/siteTheme/' + siteId + '/categoryContentTemplates';

            var requestConfig = {
                url: endpoint,
                method: 'GET'
            };

            return $http(requestConfig)
                .then(function (response) {
                    return UtilService.unpackListResponse(response, 'strings');
                })
                .catch(function (response) {
                    return $q.reject('An error occurred getting category content templates by site id ' + siteId);
                });
        }

        function getContentPageContentTemplates (siteId) {
            var endpoint = restRootUrl + '/themes/siteTheme/' + siteId + '/contentPageContentTemplates';

            var requestConfig = {
                url: endpoint,
                method: 'GET'
            };

            return $http(requestConfig)
                .then(function (response) {
                    return UtilService.unpackListResponse(response, 'strings');
                })
                .catch(function (response) {
                    return $q.reject('An error occurred getting content page content templates by site id ' + siteId);
                });
        }

        function getCategoryRootUrl (siteId) {
            var endpoint = restRootUrl + '/themes/siteTheme/' + siteId + '/categoryRootUrl';

            var requestConfig = {
                url: endpoint,
                method: 'GET'
            };

            return $http(requestConfig)
                .then(function (response) {
                    return response.data.content;
                })
                .catch(function (response) {
                    return $q.reject('An error occurred getting category root url by site id ' + siteId);
                });
        }

        function getContentPageRootUrl (siteId) {
            var endpoint = restRootUrl + '/themes/siteTheme/' + siteId + '/contentPageRootUrl';

            var requestConfig = {
                url: endpoint,
                method: 'GET'
            };

            return $http(requestConfig)
                .then(function (response) {
                    return response.data.content;
                })
                .catch(function (response) {
                    return $q.reject('An error occurred getting content page root url by site id ' + siteId);
                });
        }

        function getCategoryRootResourceUrl (siteId) {
            var endpoint = restRootUrl + '/themes/siteTheme/' + siteId + '/categoryRootResourceUrl';

            var requestConfig = {
                url: endpoint,
                method: 'GET'
            };

            return $http(requestConfig)
                .then(function (response) {
                    return response.data.content;
                })
                .catch(function (response) {
                    return $q.reject('An error occurred getting category root resource url by site id ' + siteId);
                });
        }

        function getContentPageRootResourceUrl (siteId) {
            var endpoint = restRootUrl + '/themes/siteTheme/' + siteId + '/contentPageRootResourceUrl';

            var requestConfig = {
                url: endpoint,
                method: 'GET'
            };

            return $http(requestConfig)
                .then(function (response) {
                    return response.data.content;
                })
                .catch(function (response) {
                    return $q.reject('An error occurred getting content page root resource url by site id ' + siteId);
                });
        }

        function convertToUrlSafeString (value) {
            var endpoint = restRootUrl + '/themes/siteTheme/convertToUrlSafeString?value=' + value;

            var requestConfig = {
                url: endpoint,
                method: 'GET'
            };

            return $http(requestConfig)
                .then(function (response) {
                    return response.data.content;
                })
                .catch(function (response) {
                    return $q.reject('An error occurred converting value ' + value + ' to url safe string ');
                });
        }

        return _service;
    }
}());
