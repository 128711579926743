(function () {
    'use strict';

    /*
        Replaces each character in a string with a single replacement character, resulting in a replacement string of the same length as the source string.

        {{"some string" | obfuscate}}        // "***********" default character is asterisk
        {{"some string" | obfuscate : '#' }} // "###########" can pass different replacement character to filter
    */

    angular
        .module('collaterateApp')
        .filter('obfuscate', obfuscate);

    function obfuscate () {
        return function (str, char) {
            var defaultChar = '*';
            var replaceChar = (char ? char.toString().charAt(0) : defaultChar) || defaultChar;

            if (!str) {
                return str;
            }

            return str.toString().split('').map(function () { return replaceChar; }).join('');
        };
    }
}());
