(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('spinner', spinner);

    function spinner () {
        return {
            restrict: 'AE',
            transclude: 'true',
            template: '<span class="median"><img ng-src="{{spinnerSrc}}" width="{{imageSize.width}}" height="{{imageSize.height}}" style="margin-right: 10px;"/> <span ng-transclude></span></span>',
            link: function spinnerDirectiveLinkFn (scope, element, attrs) {
                if (!attrs.hasOwnProperty('small')) {
                    scope.spinnerSrc = '/web3/build/admin/images/spinner_big.gif';
                    scope.imageSize = {
                        width: 32,
                        height: 32
                    };
                } else {
                    scope.spinnerSrc = '/web3/build/admin/images/spinner_small.gif';
                    scope.imageSize = {
                        width: 16,
                        height: 16
                    };
                }
            }
        };
    }
}());

