(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .constant('tinyMcePresets', {
            html_editor: {
                plugins: 'code link',
                toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright justify | bullist numlist outdent indent | code link',
                menubar: 'edit view format'
            },
            lists_only: {
                menubar: false,
                toolbar: 'bullist numlist',
                forced_root_block: 'div'
            }
        });
}());
