(function () {
    angular
        .module('collaterateApp')
        .factory('rootUrl', rootUrl);

    rootUrl.$inject = ['$location', 'RestUrlService'];

    function rootUrl ($location, RestUrlService) {
        var _siteAdminUrl;

        return {
            setUrl: setUrl,
            getUrl: getUrl
        };

        function setUrl (subDomainPrefix) {
            var prefix = subDomainPrefix ? subDomainPrefix + '.' : '';

            // NJD : This isn't being used yet...
            // but the idea is to generate a full url to be used when making rest calls that includes the subdomain for site identification
            _siteAdminUrl = $location.protocol() + '://' + prefix + $location.host() + RestUrlService.getRestRootUrl();
        }

        function getUrl () {
            return _siteAdminUrl;
        }
    }
}());

