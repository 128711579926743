(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('ProductPriceLineChart', getProductPriceLineChartConstructor);

    getProductPriceLineChartConstructor.$inject = ['wholesalePriceStrategyTypes', 'wholesalePriceStrategySubTypes'];

    function getProductPriceLineChartConstructor (wholesalePriceStrategyTypes, wholesalePriceStrategySubTypes) {
        function ProductPriceLineChart (data, configArg) {
            this.config = {
                chart: {
                    width: 538,
                    type: 'line'
                },
                series: {
                    line: {
                        data: [],
                        showInLegend: false
                    }
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: false
                },
                title: {
                    text: 'Markup',
                    align: 'left',
                    style: {
                        color: '#5f5f5f',
                        fontFamily: '"Proxima Nova Soft W03 Reg", Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif',
                        fontSize: '13px'
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-size: 10px">Device Throughput: {point.key}</span><br/>',
                    pointFormat: '<span style="font-size: 10px">Markup: {point.y}%</span><br/>'
                },
                subtitle: {
                    text: 'The line displays the markup percent applied at the calculated Quantity.',
                    align: 'left',
                    style: {
                        color: '#5f5f5f',
                        fontFamily: '"Proxima Nova Soft W03 Reg", Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif',
                        fontSize: '13px'
                    }
                },
                xAxis: {
                    // tickInterval: 50,
                    min: 1,
                    style: {
                        fontSize: '9px'
                    }
                },
                yAxis: {
                    // tickInterval: 25,
                    softMin: 0,
                    style: {
                        fontSize: '8px'
                    },
                    title: {
                        text: 'Markup Percent'
                    }
                }
            };

            angular.merge(this.config, configArg);

            if (data) {
                this.update(data);
            }
        }

        ProductPriceLineChart.prototype.update = function (data) {
            generateSeriesData(data, this);
            generateTooltipOptions(data, this);
            generateTitleOptions(data, this);
            generateSubtitleOptions(data, this);
            generateYAxisOptions(data, this);
        };

        return ProductPriceLineChart;

        //
        // Class Helpers
        //

        function generateSeriesData (data, instance) {
            instance.config.series.line.data = [];

            instance.config.series.line.data.push({
                x: 1,
                y: data.initialDataValue,
                marker: { enabled: true }
            });

            data.data.forEach(function (marker) {
                instance.config.series.line.data.push({
                    x: marker.point,
                    y: marker.value,
                    marker: { enabled: true }
                });
            }, instance);

            if (data.strategy.type === wholesalePriceStrategyTypes.QTY_TIERED) {
                instance.config.series.line.step = 'left';
            } else if (instance.config.series.line.step) {
                delete instance.config.series.line.step;
            }
        }

        function generateTooltipOptions (data, instance) {
            var headerLabel = '';
            var pointLabel = '';
            var pointSuffix = '';
            var pointPrefix = '';

            switch (data.strategy.type) {
                case wholesalePriceStrategyTypes.QTY_TIERED:
                    headerLabel = 'Tier Level';
                    pointLabel = 'Price Per Unit';
                    pointPrefix = '$';
                    break;
                case wholesalePriceStrategyTypes.SCALED_QUOTE_MARKUP:
                    headerLabel = data.getMarkupDescription();
                    pointLabel = 'Markup';
                    pointSuffix = '%';
                    break;
            }

            instance.config.tooltip = {
                headerFormat: '<span style="font-size: 10px">' + headerLabel + ': {point.key}</span><br/>',
                pointFormat: '<span style="font-size: 10px">' + pointLabel + ': ' + pointPrefix + '{point.y}' + pointSuffix + '</span><br/>'
            };
        }

        function generateTitleOptions (data, instance) {
            instance.config.title.text = data.strategy.type === wholesalePriceStrategyTypes.QTY_TIERED ? 'Price' : 'Markup';
        }

        function generateSubtitleOptions (data, instance) {
            if (data.initialDataValue === null) {
                instance.config.subtitle.text = 'Add the initial ' + (data.strategy.type === wholesalePriceStrategyTypes.QTY_TIERED ? 'price ' : 'markup ') + 'to get started.';
                return;
            }

            instance.config.subtitle.text = data.strategy.type === wholesalePriceStrategyTypes.QTY_TIERED
                ? 'The line displays the price per unit at each Tier threshhold.'
                : 'The line displays the markup percent applied at the calculated ' + data.getMarkupDescription() + '.';
        }

        function generateYAxisOptions (data, instance) {
            instance.config.yAxis.title.text = data.strategy.type === wholesalePriceStrategyTypes.QTY_TIERED ? 'Price Per Unit' : 'Markup Percent';
        }
    }
}());
