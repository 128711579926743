(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('csrService', csrService);

    csrService.$inject = ['$http', '$q', 'restRootUrl'];

    function csrService ($http, $q, restRootUrl) {
        var allCSRsEndpoint = restRootUrl + '/users/csr/all';
        var findCSRsEndpoint = restRootUrl + '/adminUsers/search';

        var service = {
            getAll: getAll,
            findCsr: findCsr
        };

        return service;

        function getAll () {
            return $http
                .get(allCSRsEndpoint)
                .catch(handleError);

            function handleError (response) {
                return $q.reject('Unexpected error getting all PCs.');
            }
        }

        function findCsr (searchText) {
            var q = $http.ioHelpers.toQueryString({
                name: searchText,
                isCsr: true
            });

            return $http
                .get(findCSRsEndpoint + '?q=' + q)
                .catch(handleError);

            function handleError (response) {
                return $q.reject('Unexpected error searching for PCs.');
            }
        }
    }
}());
