// Developers note: there are three directives defined in this module.  I couldn't get the transculsion right in the first one so that I could make the node
// be available at the controller level for setting events and such, so I created the next to directives which exposed more of the structure DOM of the tree
// than I wanted, but I was able to get the events hooked up right.  I would like to works with this more and ultimately get the first tree directive to work
// correctly
(function () {
    'use strict';
    var mod = angular.module('collaterate.common.directives.simpletree', []);

    mod.directive('collaterateSimpleTree', collaterateSimpleTree);
    collaterateSimpleTree.$inject = ['$compile', '$log'];

    function collaterateSimpleTree ($compile, $log) {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                family: '='
            },
            templateUrl: '/templates/simpletree.template.html',
            compile: function (tElement, tAttr, transclude) {
                var contents = tElement.contents().remove();
                var compiledContents;
                return function (scope, iElement, iAttr) {
                    if (!compiledContents) {
                        compiledContents = $compile(contents, transclude);
                    }
                    compiledContents(scope, function (clone, scope) {
                        iElement.append(clone);
                    });
                };
            }
        };
    }

    // The `collaterate-tree-repeater` directive is the main and outer directive. Use this to
    // define your tree structure in the form `varName in collection of root`
    // where:
    //  - varName is the scope variable used for each node in the tree.
    //  - collection is the collection of children within each node.
    //  - root is the root node.
    //
    mod.directive('collaterateTreeRepeater', collaterateTreeRepeater);
    collaterateTreeRepeater.$inject = ['$log'];

    function collaterateTreeRepeater ($log) {
        return {
            restrict: 'A',

            // Use a scope to attach the node model
            scope: true,

            // and a controller to communicate the template params to `sf-treecurse`
            controller: [
                '$scope',
                '$attrs',
                function TreepeatController ($scope, $attrs) {
                    var ident = this.ident = parseTreeRepeater($attrs.collaterateTreeRepeater);

                    // $log.info("Parsed '%s' as %s", $attrs.collaterateTreeRepeater, JSON.stringify(this.ident));
                    // Keep the root node up to date.
                    $scope.$watch(this.ident.root, function (v) {
                        $scope[ident.value] = v;
                    });
                }
            ],

            // Get the original element content HTML to use as the recursive template
            compile: function collaterateTreeRecursionCompile (element) {
                var template = element.html();
                return {
                    // set it in the pre-link so we can use it lower down
                    pre: function collaterateTreeRepeaterPreLink (scope, iterStartElement, attrs, controller) {
                        controller.template = template;
                    }
                };
            }
        };
    }

    // The `collaterate-tree-recursion` directive is a little like `ng-transclude` in that it
    // signals where to insert our recursive template
    mod.directive('collaterateTreeRecursion', collaterateTreeRecursion);
    collaterateTreeRecursion.$inject = ['$compile'];

    function collaterateTreeRecursion ($compile) {
        return {
            // which must come from a parent `collaterate-tree-repeater`.
            require: '^collaterateTreeRepeater',
            link: function collaterateTreeRecursionPostLink (scope, iterStartElement, attrs, controller) {
                // Now we stitch together an element containing a vanilla repeater using
                // the values from the controller.
                var build = ['<', iterStartElement.context.tagName, ' ng-repeat="', controller.ident.value, ' in ', controller.ident.value, '.', controller.ident.collection, '">', controller.template, '</', iterStartElement.context.tagName, '>'];
                var el = angular.element(build.join(''));

                // We swap out the element for our new one and tell angular to do its
                // thing with that.
                iterStartElement.replaceWith(el);
                $compile(el)(scope);
            }
        };
    }

    // Utility function to turn the expression supplied to the directive:
    //
    //     a in b of c
    //
    // into `{ value: "a", collection: "b", root: "c" }`
    //
    function parseTreeRepeater (expression) {
        var match = expression.match(/^\s*([\$\w]+)\s+in\s+([\S\s]*)\s+of\s+([\S\s]*)$/);
        if (!match) {
            throw new Error('Could not parse [node] in [children] of [tree] repeater expression.');
        }
        return {
            value: match[1],
            collection: match[2],
            root: match[3]
        };
    }
}());
