(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('popupHtml', popupHtml);

    popupHtml.$inject = ['$uibModal'];

    function popupHtml ($uibModal) {
        return {
            restrict: 'A',
            scope: {
                content: '=popupHtml',
                title: '@popupTitle'
            },
            link: function popupHtml_linkFn (scope, element, attrs, controllers) {
                element.on('click', function (e) {
                    e.preventDefault();

                    $uibModal.open({
                        backdrop: 'static',
                        windowClass: 'med-modal',
                        templateUrl: '/templates/popup-html.template.html',
                        controller: ['content', 'title', function (content, title) {
                            this.content = content;
                            this.title = title;
                        }],
                        controllerAs: 'vm',
                        resolve: {
                            content: function () {
                                return scope.content;
                            },
                            title: function () {
                                return scope.title;
                            }
                        }
                    });
                });
            }
        };
    }
}());
