(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('stopClickPropagation', stopClickPropagation);

    function stopClickPropagation () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var enabled = false;
                var destroyFn = angular.noop;

                init();

                return;

                function init () {
                    enableUI();
                }

                function enableUI () {
                    if (enabled) {
                        return;
                    }

                    element.on('click', handleClickEvent);
                    destroyFn = scope.$on('$destory', disableUI);

                    enabled = true;
                }

                function disableUI () {
                    if (!enabled) {
                        return;
                    }

                    element.off('click', handleClickEvent);
                    destroyFn();

                    enabled = false;
                }

                function handleClickEvent (e) {
                    e.stopPropagation();
                }
            }
        };
    }
}());

