(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .factory('serializeParams', serializeParams);

    serializeParams.$inject = ['$window'];

    function serializeParams ($window) {
        return function (params) {
            var paramsCopy = angular.copy(params);
            clearEmptyParams(paramsCopy);

            // https://github.com/ljharb/qs
            return $window.Qs.stringify(paramsCopy, {
                allowDots: true,
                addQueryPrefix: true
            });
        };
    }

    function clearEmptyParams (input) {
        for (var param in input) {
            if (input.hasOwnProperty(param)) {
                if (input[param] === null || typeof input[param] === 'undefined' || input[param] === '') {
                    delete input[param];
                    continue;
                }

                if (input[param].constructor === Object) {
                    clearEmptyParams(input[param]);
                }
            }
        }
    }
}());
