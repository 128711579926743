(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('localStorageService', localStorageService);

    localStorageService.$inject = ['$window', '$log', 'localStorageKeys'];

    function localStorageService ($window, $log, localStorageKeys) {
        var service = {
            get: getStorageItem,
            set: setStorageItem,
            keys: localStorageKeys
        };

        return service;

        function getStorageItem (key) {
            var storageObject;

            if (!localStorageKeys[key]) {
                $log.warn('Local storage key "' + key + '" not found in localStorageKeys service. It is recommended to add all required localStorage keys to this service.');
            }

            storageObject = $window.localStorage.getItem(key);

            if (storageObject && storageObject !== 'undefined') {
                return JSON.parse(storageObject);
            }

            return null;
        }

        function setStorageItem (key, data) {
            if (!localStorageKeys[key]) {
                $log.warn('Local storage key "' + key + '" not found in localStorageKeys service. It is recommended to add all required localStorage keys to this service.');
            }

            if (typeof data === 'undefined') {
                $window.localStorage.removeItem(key);
                return;
            }

            $window.localStorage.setItem(key, JSON.stringify(data));
        }
    }
}());
