(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('integer', integer);

    integer.$inject = [];

    function integer () {
        var INTEGER_REGEX = /^\d+$/;

        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, element, attrs, ngModelController) {
                ngModelController.$parsers.unshift(function (viewValue) {
                    if (INTEGER_REGEX.test(viewValue)) {
                        ngModelController.$setValidity('integer', true);
                        return viewValue;
                    }
                    ngModelController.$setValidity('integer', false);
                    return undefined;
                });
            }
        };
    }
}());
