(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .directive('paginationRemote', paginationRemote);

    paginationRemote.$inject = [];

    function paginationRemote () {
        return {
            restrict: 'AE',
            replace: true,
            templateUrl: '/templates/pagination-remote.template.html',
            transclude: {
                header: '?paginationRemoteHeader',
                headerFt: '?paginationRemoteHeaderFt'
            },
            scope: {
                source: '=',
                items: '=',
                sortFn: '=?',
                fetchFn: '=?',
                sortProps: '=?',
                params: '=?',
                paramsStorage: '=?',
                sortPropsStorage: '=?',
                loading: '=?',
                hidePageMenu: '=?',
                hidePrevNext: '=?',
                hideSizeMenu: '=?',
                hideInfo: '=?',
                hideTopControls: '=?',
                hideBottomControls: '=?'
            },
            controller: paginationRemoteController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    paginationRemoteController.$inject = ['$element', '$window', '$timeout'];

    function paginationRemoteController ($element, $window, $timeout) {
        var vm = this;

        vm.pageSizeOptions = [
            { name: '25 per page', value: 25 },
            { name: '50 per page', value: 50 },
            { name: '100 per page', value: 100 },
            { name: '250 per page', value: 250 },
            { name: '500 per page', value: 500 }
        ];

        vm.itemsPerPage = null;
        vm.currentRangeStart = 0;
        vm.currentRangeEnd = 0;
        vm.currentTotalElements = 0;
        vm.pageRange = [];
        vm.hidePageMenu = !!vm.hidePageMenu;
        vm.hidePrevNext = !!vm.hidePrevNext;
        vm.hideSizeMenu = !!vm.hideSizeMenu;
        vm.hideInfo = !!vm.hideInfo;
        vm.hideTopControls = !!vm.hideTopControls;
        vm.hideBottomControls = !!vm.hideBottomControls;
        vm.params = vm.params || {};
        vm.sortProps = vm.sortProps || {};
        vm.previous = false;
        vm.next = false;

        vm.changePage = changePage;
        vm.sortFn = sort;
        vm.fetchFn = fetch;

        init();

        return;

        function init () {
            enableLoadingState();

            vm.source(vm.sortProps, vm.params)
                .then(setState)
                .finally(disableLoadingState);
        }

        function setState (paginatedList) {
            vm.previous = paginatedList.previous;
            vm.next = paginatedList.next;
            vm.items = paginatedList.items;
            vm.sortProps = paginatedList.page;
            vm.sortProps.secondarySorts = []; // As this directive only deals with setting a primary sort, and to allow the API to properly cascade default sorts, do not store secondary sorts locally.
            vm.currentTotalElements = vm.sortProps.totalElements;
            vm.currentRangeStart = vm.currentTotalElements > 0
                ? ((vm.sortProps.number * vm.sortProps.size) - vm.sortProps.size) + 1
                : 0;
            vm.currentRangeEnd = vm.sortProps.number * vm.sortProps.size > vm.sortProps.totalElements
                ? vm.sortProps.totalElements
                : vm.sortProps.number * vm.sortProps.size;
            vm.pageRange = generatePageRange(vm.sortProps.totalPages);
            vm.itemsPerPage = vm.sortProps.size;
            vm.paramsStorage && vm.paramsStorage(vm.params);
            vm.sortPropsStorage && vm.sortPropsStorage(vm.sortProps);
        }

        function changePage (number) {
            enableLoadingState();

            vm.source(Object.assign({}, vm.sortProps, {
                number: number
            }), vm.params)
                .then(setState)
                .finally(disableLoadingState)
                .finally(resetScroll);
        }

        function sort (prop) {
            enableLoadingState();

            var ascending = vm.sortProps.sort.fieldName === prop
                ? !vm.sortProps.sort.ascending
                : true;

            vm.source(Object.assign({}, vm.sortProps, {
                number: 1,
                sort: { fieldName: prop, ascending: ascending }
            }), vm.params)
                .then(setState)
                .finally(disableLoadingState);
        }

        function fetch () {
            return changePage(1);
        }

        function generatePageRange (pages) {
            var range = [];

            for (var i = 1; i <= pages; i++) {
                range.push({ index: i });
            }

            return range;
        }

        function enableLoadingState () {
            vm.loading = true;
        }

        function disableLoadingState () {
            vm.loading = false;
        }

        function resetScroll () {
            $timeout(function () {
                var target = $element.offset().top;

                $window.scrollY > target && $window.scrollTo(0, target);
            });
        }
    }
}());
