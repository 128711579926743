(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('supplierIdService', supplierIdService);

    supplierIdService.$inject = ['$window'];

    function supplierIdService ($window) {
        var service = {
            get: getSupplierId
        };

        return service;

        function getSupplierId () {
            return $window.globals ? $window.globals.supplierId : null;
        }
    }
}());
