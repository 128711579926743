(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .constant('BulkActions', BulkActions);

    function BulkActions (id) {
        this.id = id;
        this.items = [];
        this.controls = [];
    }

    BulkActions.prototype.addItem = function (item) {
        if (indexInCollection(item, this.items) === -1) {
            this.items.push(item);
        }
    };

    BulkActions.prototype.removeItem = function (item) {
        var index = indexInCollection(item, this.items);

        if (index > -1) {
            return this.items.splice(index, 1);
        }
    };

    BulkActions.prototype.addControl = function (control) {
        if (indexInCollection(control, this.controls) === -1) {
            this.controls.push(control);
        }
    };

    BulkActions.prototype.removeControl = function (control) {
        var index = indexInCollection(control, this.controls);

        if (index > -1) {
            return this.controls.splice(index, 1);
        }
    };

    // Returns index of object in a collection or -1 if not found
    function indexInCollection (item, collection) {
        for (var i = 0; i < collection.length; i++) {
            if (angular.equals(item, collection[i])) {
                return i;
            }
        }

        return -1;
    }
}());
