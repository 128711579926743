(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('secure', secure);

    secure.$inject = ['CollaterateSecurityService', 'permissions'];

    function secure (CollaterateSecurityService, permissions) {
        return {
            restrict: 'A',
            priority: '500',
            scope: {
                canRead: '=',
                canEdit: '=',
                canCreate: '='
            },
            transclude: 'element',
            link: function (scope, element, attrs, controller, transclude) {
                var childElement = null;
                var childScope = null;

                var unwatch = scope.$watch(watchSecurityService, determinePermissions);

                function determinePermissions () {
                    for (var permission in permissions) {
                        if (permissions.hasOwnProperty(permission)) {
                            if (attrs.hasOwnProperty(permissions[permission]) && attrs[permissions[permission]]) {
                                CollaterateSecurityService.hasPermission(scope[permissions[permission]], permissions[permission])
                                    ? enableElement()
                                    : destroyElement();
                            }
                        }
                    }
                }

                function enableElement () {
                    if (childScope || childElement) {
                        return;
                    }

                    childScope = scope.$parent.$new();
                    childElement = transclude(childScope, function (clone) {
                        element.after(clone);
                    });
                }

                function destroyElement () {
                    if (!childScope || !childElement) {
                        return;
                    }

                    childScope.$destroy();
                    childElement.remove();
                    childScope = null;
                    childElement = null;
                }

                function watchSecurityService () {
                    return CollaterateSecurityService.lastUpdated();
                }
            }
        };
    }
}());
