(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('bulkActionsInclude', bulkActionsInclude);

    function bulkActionsInclude () {
        return {
            restrict: 'AE',
            replace: true,
            templateUrl: '/templates/bulk-actions-include.template.html',
            scope: {
                item: '=bulkActionsInclude',
                bulkActionsId: '@'
            },
            controller: BulkActionsIncludeController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    BulkActionsIncludeController.$inject = ['$scope', 'bulkActionsService'];

    function BulkActionsIncludeController ($scope, bulkActionsService) {
        var vm = this;

        vm.checked = false;

        vm.addItem = addItem;
        vm.removeItem = removeItem;

        init();

        return;

        function init () {
            addControl();
            $scope.$on('$destroy', handleDestroy);
        }

        function addControl () {
            bulkActionsService.getInstance(vm.bulkActionsId).addControl(vm);
        }

        function removeControl () {
            bulkActionsService.getInstance(vm.bulkActionsId).removeControl(vm);
        }

        function addItem () {
            bulkActionsService.getInstance(vm.bulkActionsId).addItem(vm.item);
        }

        function removeItem () {
            bulkActionsService.getInstance(vm.bulkActionsId).removeItem(vm.item);
        }

        function handleDestroy () {
            removeControl();
            removeItem();
        }
    }
}());
