(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .filter('htmlToText', htmlToText);

    function htmlToText () {
        return function (html) {
            return html ? $('<div>' + html + '</div>').text() : '';
        };
    }
}());
