(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .factory('PaginationOptions', getPaginationOptionsConstructor);

    getPaginationOptionsConstructor.$inject = ['paginationDefaults'];

    function getPaginationOptionsConstructor (paginationDefaults) {
        function PaginationOptions (paginationOptions) {
            var options = Object.assign({}, paginationDefaults, paginationOptions);

            this.number = options.number;
            this.size = options.size;
            this.sort = options.sort ? [getApiSort(options.sort)] : [];

            if (paginationOptions.secondarySorts) {
                paginationOptions.secondarySorts.forEach(function (sort) {
                    this.sort.push(getApiSort(sort));
                }, this);
            }
        }

        return PaginationOptions;

        function getApiSort (sort) {
            return {
                fieldName: sort.fieldName,
                direction: sort.ascending ? 'ASC' : 'DESC'
            };
        }
    }
}());
