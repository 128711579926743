(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .controller('PopupController', PopupController);

    PopupController.$inject = ['$scope', 'src', 'title'];

    function PopupController ($scope, src, title) {
        var vm = this;
        vm.src = src;
        vm.title = title || 'Image';
    }
}());
