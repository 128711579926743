(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('tbgPopup', popup);

    popup.$inject = ['$uibModal'];

    function popup ($uibModal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var href = attrs.href || null;
                var title = attrs.popupTitle || null;

                if (href && href.length) {
                    init();
                }

                return;

                function init () {
                    enableUI();
                }

                function enableUI () {
                    element.on('click.tbgpopup', function (e) {
                        e.preventDefault();
                        spawnPopup();
                    });
                }

                function spawnPopup () {
                    var modalSettings = {
                        windowClass: 'img-modal',
                        templateUrl: '/templates/popup.template.html',
                        controller: 'PopupController as vm',
                        resolve: {
                            src: function () {
                                return href;
                            },
                            title: function () {
                                return title;
                            }
                        }
                    };

                    return $uibModal.open(modalSettings).result;
                }
            }
        };
    }
}());
