(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .constant('TbgMdColor', TbgMdColor);

    var colors = {
        textClasses: {
            default: '',
            base: 'mdColor',
            light: 'mix--mdColor_lightStrong',
            dark: 'mix--mdColor_dark'
        },
        colorClasses: {
            '#ffebee': {
                colorClass: 'mdColor_red50',
                textColor: 'dark'
            },
            '#ffcdd2': {
                colorClass: 'mdColor_red100',
                textColor: 'dark'
            },
            '#ef9a9a': {
                colorClass: 'mdColor_red200',
                textColor: 'dark'
            },
            '#e57373': {
                colorClass: 'mdColor_red300',
                textColor: 'dark'
            },
            '#ef5350': {
                colorClass: 'mdColor_red400',
                textColor: 'default'
            },
            '#f44336': {
                colorClass: 'mdColor_red500',
                textColor: 'default'
            },
            '#e53935': {
                colorClass: 'mdColor_red600',
                textColor: 'default'
            },
            '#d32f2f': {
                colorClass: 'mdColor_red700',
                textColor: 'default'
            },
            '#c62828': {
                colorClass: 'mdColor_red800',
                textColor: 'default'
            },
            '#b71c1c': {
                colorClass: 'mdColor_red900',
                textColor: 'default'
            },
            '#ff8a80': {
                colorClass: 'mdColor_redA100',
                textColor: 'dark'
            },
            '#ff5252': {
                colorClass: 'mdColor_redA200',
                textColor: 'light'
            },
            '#ff1744': {
                colorClass: 'mdColor_redA400',
                textColor: 'default'
            },
            '#d50000': {
                colorClass: 'mdColor_redA700',
                textColor: 'default'
            },
            '#fce4ec': {
                colorClass: 'mdColor_pink50',
                textColor: 'dark'
            },
            '#f8bbd0': {
                colorClass: 'mdColor_pink100',
                textColor: 'dark'
            },
            '#f48fb1': {
                colorClass: 'mdColor_pink200',
                textColor: 'dark'
            },
            '#f06292': {
                colorClass: 'mdColor_pink300',
                textColor: 'light'
            },
            '#ec407a': {
                colorClass: 'mdColor_pink400',
                textColor: 'default'
            },
            '#e91e63': {
                colorClass: 'mdColor_pink500',
                textColor: 'default'
            },
            '#d81b60': {
                colorClass: 'mdColor_pink600',
                textColor: 'default'
            },
            '#c2185b': {
                colorClass: 'mdColor_pink700',
                textColor: 'default'
            },
            '#ad1457': {
                colorClass: 'mdColor_pink800',
                textColor: 'default'
            },
            '#880e4f': {
                colorClass: 'mdColor_pink900',
                textColor: 'default'
            },
            '#ff80ab': {
                colorClass: 'mdColor_pinkA100',
                textColor: 'dark'
            },
            '#ff4081': {
                colorClass: 'mdColor_pinkA200',
                textColor: 'light'
            },
            '#f50057': {
                colorClass: 'mdColor_pinkA400',
                textColor: 'default'
            },
            '#c51162': {
                colorClass: 'mdColor_pinkA700',
                textColor: 'default'
            },
            '#f3e5f5': {
                colorClass: 'mdColor_purple50',
                textColor: 'dark'
            },
            '#e1bee7': {
                colorClass: 'mdColor_purple100',
                textColor: 'dark'
            },
            '#ce93d8': {
                colorClass: 'mdColor_purple200',
                textColor: 'dark'
            },
            '#ba68c8': {
                colorClass: 'mdColor_purple300',
                textColor: 'light'
            },
            '#ab47bc': {
                colorClass: 'mdColor_purple400',
                textColor: 'light'
            },
            '#9c27b0': {
                colorClass: 'mdColor_purple500',
                textColor: 'default'
            },
            '#8e24aa': {
                colorClass: 'mdColor_purple600',
                textColor: 'default'
            },
            '#7b1fa2': {
                colorClass: 'mdColor_purple700',
                textColor: 'default'
            },
            '#6a1b9a': {
                colorClass: 'mdColor_purple800',
                textColor: 'default'
            },
            '#4a148c': {
                colorClass: 'mdColor_purple900',
                textColor: 'default'
            },
            '#ea80fc': {
                colorClass: 'mdColor_purpleA100',
                textColor: 'dark'
            },
            '#e040fb': {
                colorClass: 'mdColor_purpleA200',
                textColor: 'light'
            },
            '#d500f9': {
                colorClass: 'mdColor_purpleA400',
                textColor: 'default'
            },
            '#aa00ff': {
                colorClass: 'mdColor_purpleA700',
                textColor: 'default'
            },
            '#ede7f6': {
                colorClass: 'mdColor_deepPurple50',
                textColor: 'dark'
            },
            '#d1c4e9': {
                colorClass: 'mdColor_deepPurple100',
                textColor: 'dark'
            },
            '#b39ddb': {
                colorClass: 'mdColor_deepPurple200',
                textColor: 'dark'
            },
            '#9575cd': {
                colorClass: 'mdColor_deepPurple300',
                textColor: 'default'
            },
            '#7e57c2': {
                colorClass: 'mdColor_deepPurple400',
                textColor: 'default'
            },
            '#673ab7': {
                colorClass: 'mdColor_deepPurple500',
                textColor: 'default'
            },
            '#5e35b1': {
                colorClass: 'mdColor_deepPurple600',
                textColor: 'default'
            },
            '#512da8': {
                colorClass: 'mdColor_deepPurple700',
                textColor: 'default'
            },
            '#4527a0': {
                colorClass: 'mdColor_deepPurple800',
                textColor: 'default'
            },
            '#311b92': {
                colorClass: 'mdColor_deepPurple900',
                textColor: 'default'
            },
            '#b388ff': {
                colorClass: 'mdColor_deepPurpleA100',
                textColor: 'dark'
            },
            '#7c4dff': {
                colorClass: 'mdColor_deepPurpleA200',
                textColor: 'light'
            },
            '#651fff': {
                colorClass: 'mdColor_deepPurpleA400',
                textColor: 'default'
            },
            '#6200ea': {
                colorClass: 'mdColor_deepPurpleA700',
                textColor: 'default'
            },
            '#e8eaf6': {
                colorClass: 'mdColor_indigo50',
                textColor: 'dark'
            },
            '#c5cae9': {
                colorClass: 'mdColor_indigo100',
                textColor: 'dark'
            },
            '#9fa8da': {
                colorClass: 'mdColor_indigo200',
                textColor: 'dark'
            },
            '#7986cb': {
                colorClass: 'mdColor_indigo300',
                textColor: 'default'
            },
            '#5c6bc0': {
                colorClass: 'mdColor_indigo400',
                textColor: 'default'
            },
            '#3f51b5': {
                colorClass: 'mdColor_indigo500',
                textColor: 'default'
            },
            '#3949ab': {
                colorClass: 'mdColor_indigo600',
                textColor: 'default'
            },
            '#303f9f': {
                colorClass: 'mdColor_indigo700',
                textColor: 'default'
            },
            '#283593': {
                colorClass: 'mdColor_indigo800',
                textColor: 'default'
            },
            '#1a237e': {
                colorClass: 'mdColor_indigo900',
                textColor: 'default'
            },
            '#8c9eff': {
                colorClass: 'mdColor_indigoA100',
                textColor: 'dark'
            },
            '#536dfe': {
                colorClass: 'mdColor_indigoA200',
                textColor: 'default'
            },
            '#3d5afe': {
                colorClass: 'mdColor_indigoA400',
                textColor: 'default'
            },
            '#304ffe': {
                colorClass: 'mdColor_indigoA700',
                textColor: 'default'
            },
            '#e3f2fd': {
                colorClass: 'mdColor_blue50',
                textColor: 'dark'
            },
            '#bbdefb': {
                colorClass: 'mdColor_blue100',
                textColor: 'dark'
            },
            '#90caf9': {
                colorClass: 'mdColor_blue200',
                textColor: 'dark'
            },
            '#64b5f6': {
                colorClass: 'mdColor_blue300',
                textColor: 'dark'
            },
            '#42a5f5': {
                colorClass: 'mdColor_blue400',
                textColor: 'dark'
            },
            '#2196f3': {
                colorClass: 'mdColor_blue500',
                textColor: 'light'
            },
            '#1e88e5': {
                colorClass: 'mdColor_blue600',
                textColor: 'default'
            },
            '#1976d2': {
                colorClass: 'mdColor_blue700',
                textColor: 'default'
            },
            '#1565c0': {
                colorClass: 'mdColor_blue800',
                textColor: 'default'
            },
            '#0d47a1': {
                colorClass: 'mdColor_blue900',
                textColor: 'default'
            },
            '#82b1ff': {
                colorClass: 'mdColor_blueA100',
                textColor: 'dark'
            },
            '#448aff': {
                colorClass: 'mdColor_blueA200',
                textColor: 'light'
            },
            '#2979ff': {
                colorClass: 'mdColor_blueA400',
                textColor: 'default'
            },
            '#2962ff': {
                colorClass: 'mdColor_blueA700',
                textColor: 'default'
            },
            '#e1f5fe': {
                colorClass: 'mdColor_lightBlue50',
                textColor: 'dark'
            },
            '#b3e5fc': {
                colorClass: 'mdColor_lightBlue100',
                textColor: 'dark'
            },
            '#81d4fa': {
                colorClass: 'mdColor_lightBlue200',
                textColor: 'dark'
            },
            '#4fc3f7': {
                colorClass: 'mdColor_lightBlue300',
                textColor: 'dark'
            },
            '#29b6f6': {
                colorClass: 'mdColor_lightBlue400',
                textColor: 'dark'
            },
            '#03a9f4': {
                colorClass: 'mdColor_lightBlue500',
                textColor: 'dark'
            },
            '#039be5': {
                colorClass: 'mdColor_lightBlue600',
                textColor: 'light'
            },
            '#0288d1': {
                colorClass: 'mdColor_lightBlue700',
                textColor: 'default'
            },
            '#0277bd': {
                colorClass: 'mdColor_lightBlue800',
                textColor: 'default'
            },
            '#01579b': {
                colorClass: 'mdColor_lightBlue900',
                textColor: 'default'
            },
            '#80d8ff': {
                colorClass: 'mdColor_lightBlueA100',
                textColor: 'dark'
            },
            '#40c4ff': {
                colorClass: 'mdColor_lightBlueA200',
                textColor: 'dark'
            },
            '#00b0ff': {
                colorClass: 'mdColor_lightBlueA400',
                textColor: 'dark'
            },
            '#0091ea': {
                colorClass: 'mdColor_lightBlueA700',
                textColor: 'light'
            },
            '#e0f7fa': {
                colorClass: 'mdColor_cyan50',
                textColor: 'dark'
            },
            '#b2ebf2': {
                colorClass: 'mdColor_cyan100',
                textColor: 'dark'
            },
            '#80deea': {
                colorClass: 'mdColor_cyan200',
                textColor: 'dark'
            },
            '#4dd0e1': {
                colorClass: 'mdColor_cyan300',
                textColor: 'dark'
            },
            '#26c6da': {
                colorClass: 'mdColor_cyan400',
                textColor: 'dark'
            },
            '#00bcd4': {
                colorClass: 'mdColor_cyan500',
                textColor: 'dark'
            },
            '#00acc1': {
                colorClass: 'mdColor_cyan600',
                textColor: 'dark'
            },
            '#0097a7': {
                colorClass: 'mdColor_cyan700',
                textColor: 'default'
            },
            '#00838f': {
                colorClass: 'mdColor_cyan800',
                textColor: 'default'
            },
            '#006064': {
                colorClass: 'mdColor_cyan900',
                textColor: 'default'
            },
            '#84ffff': {
                colorClass: 'mdColor_cyanA100',
                textColor: 'dark'
            },
            '#18ffff': {
                colorClass: 'mdColor_cyanA200',
                textColor: 'dark'
            },
            '#00e5ff': {
                colorClass: 'mdColor_cyanA400',
                textColor: 'dark'
            },
            '#00b8d4': {
                colorClass: 'mdColor_cyanA700',
                textColor: 'dark'
            },
            '#e0f2f1': {
                colorClass: 'mdColor_teal50',
                textColor: 'dark'
            },
            '#b2dfdb': {
                colorClass: 'mdColor_teal100',
                textColor: 'dark'
            },
            '#80cbc4': {
                colorClass: 'mdColor_teal200',
                textColor: 'dark'
            },
            '#4db6ac': {
                colorClass: 'mdColor_teal300',
                textColor: 'dark'
            },
            '#26a69a': {
                colorClass: 'mdColor_teal400',
                textColor: 'dark'
            },
            '#009688': {
                colorClass: 'mdColor_teal500',
                textColor: 'light'
            },
            '#00897b': {
                colorClass: 'mdColor_teal600',
                textColor: 'light'
            },
            '#00796b': {
                colorClass: 'mdColor_teal700',
                textColor: 'light'
            },
            '#00695c': {
                colorClass: 'mdColor_teal800',
                textColor: 'default'
            },
            '#004d40': {
                colorClass: 'mdColor_teal900',
                textColor: 'default'
            },
            '#a7ffeb': {
                colorClass: 'mdColor_tealA100',
                textColor: 'dark'
            },
            '#64ffda': {
                colorClass: 'mdColor_tealA200',
                textColor: 'dark'
            },
            '#1de9b6': {
                colorClass: 'mdColor_tealA400',
                textColor: 'dark'
            },
            '#00bfa5': {
                colorClass: 'mdColor_tealA700',
                textColor: 'dark'
            },
            '#e8f5e9': {
                colorClass: 'mdColor_green50',
                textColor: 'dark'
            },
            '#c8e6c9': {
                colorClass: 'mdColor_green100',
                textColor: 'dark'
            },
            '#a5d6a7': {
                colorClass: 'mdColor_green200',
                textColor: 'dark'
            },
            '#81c784': {
                colorClass: 'mdColor_green300',
                textColor: 'dark'
            },
            '#66bb6a': {
                colorClass: 'mdColor_green400',
                textColor: 'dark'
            },
            '#4caf50': {
                colorClass: 'mdColor_green500',
                textColor: 'dark'
            },
            '#43a047': {
                colorClass: 'mdColor_green600',
                textColor: 'light'
            },
            '#388e3c': {
                colorClass: 'mdColor_green700',
                textColor: 'default'
            },
            '#2e7d32': {
                colorClass: 'mdColor_green800',
                textColor: 'default'
            },
            '#1b5e20': {
                colorClass: 'mdColor_green900',
                textColor: 'default'
            },
            '#b9f6ca': {
                colorClass: 'mdColor_greenA100',
                textColor: 'dark'
            },
            '#69f0ae': {
                colorClass: 'mdColor_greenA200',
                textColor: 'dark'
            },
            '#00e676': {
                colorClass: 'mdColor_greenA400',
                textColor: 'dark'
            },
            '#00c853': {
                colorClass: 'mdColor_greenA700',
                textColor: 'dark'
            },
            '#f1f8e9': {
                colorClass: 'mdColor_lightGreen50',
                textColor: 'dark'
            },
            '#dcedc8': {
                colorClass: 'mdColor_lightGreen100',
                textColor: 'dark'
            },
            '#c5e1a5': {
                colorClass: 'mdColor_lightGreen200',
                textColor: 'dark'
            },
            '#aed581': {
                colorClass: 'mdColor_lightGreen300',
                textColor: 'dark'
            },
            '#9ccc65': {
                colorClass: 'mdColor_lightGreen400',
                textColor: 'dark'
            },
            '#8bc34a': {
                colorClass: 'mdColor_lightGreen500',
                textColor: 'dark'
            },
            '#7cb342': {
                colorClass: 'mdColor_lightGreen600',
                textColor: 'dark'
            },
            '#689f38': {
                colorClass: 'mdColor_lightGreen700',
                textColor: 'light'
            },
            '#558b2f': {
                colorClass: 'mdColor_lightGreen800',
                textColor: 'default'
            },
            '#33691e': {
                colorClass: 'mdColor_lightGreen900',
                textColor: 'default'
            },
            '#ccff90': {
                colorClass: 'mdColor_lightGreenA100',
                textColor: 'dark'
            },
            '#b2ff59': {
                colorClass: 'mdColor_lightGreenA200',
                textColor: 'dark'
            },
            '#76ff03': {
                colorClass: 'mdColor_lightGreenA400',
                textColor: 'dark'
            },
            '#64dd17': {
                colorClass: 'mdColor_lightGreenA700',
                textColor: 'dark'
            },
            '#f9fbe7': {
                colorClass: 'mdColor_lime50',
                textColor: 'dark'
            },
            '#f0f4c3': {
                colorClass: 'mdColor_lime100',
                textColor: 'dark'
            },
            '#e6ee9c': {
                colorClass: 'mdColor_lime200',
                textColor: 'dark'
            },
            '#dce775': {
                colorClass: 'mdColor_lime300',
                textColor: 'dark'
            },
            '#d4e157': {
                colorClass: 'mdColor_lime400',
                textColor: 'dark'
            },
            '#cddc39': {
                colorClass: 'mdColor_lime500',
                textColor: 'dark'
            },
            '#c0ca33': {
                colorClass: 'mdColor_lime600',
                textColor: 'dark'
            },
            '#afb42b': {
                colorClass: 'mdColor_lime700',
                textColor: 'dark'
            },
            '#9e9d24': {
                colorClass: 'mdColor_lime800',
                textColor: 'dark'
            },
            '#827717': {
                colorClass: 'mdColor_lime900',
                textColor: 'default'
            },
            '#f4ff81': {
                colorClass: 'mdColor_limeA100',
                textColor: 'dark'
            },
            '#eeff41': {
                colorClass: 'mdColor_limeA200',
                textColor: 'dark'
            },
            '#c6ff00': {
                colorClass: 'mdColor_limeA400',
                textColor: 'dark'
            },
            '#aeea00': {
                colorClass: 'mdColor_limeA700',
                textColor: 'dark'
            },
            '#fffde7': {
                colorClass: 'mdColor_yellow50',
                textColor: 'dark'
            },
            '#fff9c4': {
                colorClass: 'mdColor_yellow100',
                textColor: 'dark'
            },
            '#fff59d': {
                colorClass: 'mdColor_yellow200',
                textColor: 'dark'
            },
            '#fff176': {
                colorClass: 'mdColor_yellow300',
                textColor: 'dark'
            },
            '#ffee58': {
                colorClass: 'mdColor_yellow400',
                textColor: 'dark'
            },
            '#ffeb3b': {
                colorClass: 'mdColor_yellow500',
                textColor: 'dark'
            },
            '#fdd835': {
                colorClass: 'mdColor_yellow600',
                textColor: 'dark'
            },
            '#fbc02d': {
                colorClass: 'mdColor_yellow700',
                textColor: 'dark'
            },
            '#f9a825': {
                colorClass: 'mdColor_yellow800',
                textColor: 'dark'
            },
            '#f57f17': {
                colorClass: 'mdColor_yellow900',
                textColor: 'dark'
            },
            '#ffff8d': {
                colorClass: 'mdColor_yellowA100',
                textColor: 'dark'
            },
            '#ffff00': {
                colorClass: 'mdColor_yellowA200',
                textColor: 'dark'
            },
            '#ffea00': {
                colorClass: 'mdColor_yellowA400',
                textColor: 'dark'
            },
            '#ffd600': {
                colorClass: 'mdColor_yellowA700',
                textColor: 'dark'
            },
            '#fff8e1': {
                colorClass: 'mdColor_amber50',
                textColor: 'dark'
            },
            '#ffecb3': {
                colorClass: 'mdColor_amber100',
                textColor: 'dark'
            },
            '#ffe082': {
                colorClass: 'mdColor_amber200',
                textColor: 'dark'
            },
            '#ffd54f': {
                colorClass: 'mdColor_amber300',
                textColor: 'dark'
            },
            '#ffca28': {
                colorClass: 'mdColor_amber400',
                textColor: 'dark'
            },
            '#ffc107': {
                colorClass: 'mdColor_amber500',
                textColor: 'dark'
            },
            '#ffb300': {
                colorClass: 'mdColor_amber600',
                textColor: 'dark'
            },
            '#ffa000': {
                colorClass: 'mdColor_amber700',
                textColor: 'dark'
            },
            '#ff8f00': {
                colorClass: 'mdColor_amber800',
                textColor: 'dark'
            },
            '#ff6f00': {
                colorClass: 'mdColor_amber900',
                textColor: 'dark'
            },
            '#ffe57f': {
                colorClass: 'mdColor_amberA100',
                textColor: 'dark'
            },
            '#ffd740': {
                colorClass: 'mdColor_amberA200',
                textColor: 'dark'
            },
            '#ffc400': {
                colorClass: 'mdColor_amberA400',
                textColor: 'dark'
            },
            '#ffab00': {
                colorClass: 'mdColor_amberA700',
                textColor: 'dark'
            },
            '#fff3e0': {
                colorClass: 'mdColor_orange50',
                textColor: 'dark'
            },
            '#ffe0b2': {
                colorClass: 'mdColor_orange100',
                textColor: 'dark'
            },
            '#ffcc80': {
                colorClass: 'mdColor_orange200',
                textColor: 'dark'
            },
            '#ffb74d': {
                colorClass: 'mdColor_orange300',
                textColor: 'dark'
            },
            '#ffa726': {
                colorClass: 'mdColor_orange400',
                textColor: 'dark'
            },
            '#ff9800': {
                colorClass: 'mdColor_orange500',
                textColor: 'dark'
            },
            '#fb8c00': {
                colorClass: 'mdColor_orange600',
                textColor: 'dark'
            },
            '#f57c00': {
                colorClass: 'mdColor_orange700',
                textColor: 'dark'
            },
            '#ef6c00': {
                colorClass: 'mdColor_orange800',
                textColor: 'light'
            },
            '#e65100': {
                colorClass: 'mdColor_orange900',
                textColor: 'default'
            },
            '#ffd180': {
                colorClass: 'mdColor_orangeA100',
                textColor: 'dark'
            },
            '#ffab40': {
                colorClass: 'mdColor_orangeA200',
                textColor: 'dark'
            },
            '#ff9100': {
                colorClass: 'mdColor_orangeA400',
                textColor: 'dark'
            },
            '#ff6d00': {
                colorClass: 'mdColor_orangeA700',
                textColor: 'dark'
            },
            '#fbe9e7': {
                colorClass: 'mdColor_deepOrange50',
                textColor: 'dark'
            },
            '#ffccbc': {
                colorClass: 'mdColor_deepOrange100',
                textColor: 'dark'
            },
            '#ffab91': {
                colorClass: 'mdColor_deepOrange200',
                textColor: 'dark'
            },
            '#ff8a65': {
                colorClass: 'mdColor_deepOrange300',
                textColor: 'dark'
            },
            '#ff7043': {
                colorClass: 'mdColor_deepOrange400',
                textColor: 'dark'
            },
            '#ff5722': {
                colorClass: 'mdColor_deepOrange500',
                textColor: 'light'
            },
            '#f4511e': {
                colorClass: 'mdColor_deepOrange600',
                textColor: 'default'
            },
            '#e64a19': {
                colorClass: 'mdColor_deepOrange700',
                textColor: 'default'
            },
            '#d84315': {
                colorClass: 'mdColor_deepOrange800',
                textColor: 'default'
            },
            '#bf360c': {
                colorClass: 'mdColor_deepOrange900',
                textColor: 'default'
            },
            '#ff9e80': {
                colorClass: 'mdColor_deepOrangeA100',
                textColor: 'dark'
            },
            '#ff6e40': {
                colorClass: 'mdColor_deepOrangeA200',
                textColor: 'dark'
            },
            '#ff3d00': {
                colorClass: 'mdColor_deepOrangeA400',
                textColor: 'default'
            },
            '#dd2c00': {
                colorClass: 'mdColor_deepOrangeA700',
                textColor: 'default'
            },
            '#efebe9': {
                colorClass: 'mdColor_brown50',
                textColor: 'dark'
            },
            '#d7ccc8': {
                colorClass: 'mdColor_brown100',
                textColor: 'dark'
            },
            '#bcaaa4': {
                colorClass: 'mdColor_brown200',
                textColor: 'dark'
            },
            '#a1887f': {
                colorClass: 'mdColor_brown300',
                textColor: 'light'
            },
            '#8d6e63': {
                colorClass: 'mdColor_brown400',
                textColor: 'default'
            },
            '#795548': {
                colorClass: 'mdColor_brown500',
                textColor: 'default'
            },
            '#6d4c41': {
                colorClass: 'mdColor_brown600',
                textColor: 'default'
            },
            '#5d4037': {
                colorClass: 'mdColor_brown700',
                textColor: 'default'
            },
            '#4e342e': {
                colorClass: 'mdColor_brown800',
                textColor: 'default'
            },
            '#3e2723': {
                colorClass: 'mdColor_brown900',
                textColor: 'default'
            },
            'xxx-#d7ccc8': {
                colorClass: 'mdColor_brownA100',
                textColor: 'default'
            },
            'xxx-#bcaaa4': {
                colorClass: 'mdColor_brownA200',
                textColor: 'default'
            },
            'xxx-#8d6e63': {
                colorClass: 'mdColor_brownA400',
                textColor: 'default'
            },
            'xxx-#5d4037': {
                colorClass: 'mdColor_brownA700',
                textColor: 'default'
            },
            '#fafafa': {
                colorClass: 'mdColor_grey50',
                textColor: 'dark'
            },
            '#f5f5f5': {
                colorClass: 'mdColor_grey100',
                textColor: 'dark'
            },
            '#eeeeee': {
                colorClass: 'mdColor_grey200',
                textColor: 'dark'
            },
            '#e0e0e0': {
                colorClass: 'mdColor_grey300',
                textColor: 'dark'
            },
            '#bdbdbd': {
                colorClass: 'mdColor_grey400',
                textColor: 'dark'
            },
            '#9e9e9e': {
                colorClass: 'mdColor_grey500',
                textColor: 'dark'
            },
            '#757575': {
                colorClass: 'mdColor_grey600',
                textColor: 'default'
            },
            '#616161': {
                colorClass: 'mdColor_grey700',
                textColor: 'default'
            },
            '#424242': {
                colorClass: 'mdColor_grey800',
                textColor: 'default'
            },
            '#212121': {
                colorClass: 'mdColor_grey900',
                textColor: 'default'
            },
            '#000000': {
                colorClass: 'mdColor_grey1000',
                textColor: 'default'
            },
            'xxx-#ffffff': {
                colorClass: 'mdColor_greyA100',
                textColor: 'default'
            },
            'xxx-#eeeeee': {
                colorClass: 'mdColor_greyA200',
                textColor: 'default'
            },
            'xxx-#bdbdbd': {
                colorClass: 'mdColor_greyA400',
                textColor: 'default'
            },
            'xxx-#616161': {
                colorClass: 'mdColor_greyA700',
                textColor: 'default'
            },
            '#eceff1': {
                colorClass: 'mdColor_blueGrey-50',
                textColor: 'dark'
            },
            '#cfd8dc': {
                colorClass: 'mdColor_blueGrey-100',
                textColor: 'dark'
            },
            '#b0bec5': {
                colorClass: 'mdColor_blueGrey-200',
                textColor: 'dark'
            },
            '#90a4ae': {
                colorClass: 'mdColor_blueGrey-300',
                textColor: 'dark'
            },
            '#78909c': {
                colorClass: 'mdColor_blueGrey-400',
                textColor: 'light'
            },
            '#607d8b': {
                colorClass: 'mdColor_blueGrey-500',
                textColor: 'default'
            },
            '#546e7a': {
                colorClass: 'mdColor_blueGrey-600',
                textColor: 'default'
            },
            '#455a64': {
                colorClass: 'mdColor_blueGrey-700',
                textColor: 'default'
            },
            '#37474f': {
                colorClass: 'mdColor_blueGrey-800',
                textColor: 'default'
            },
            '#263238': {
                colorClass: 'mdColor_blueGrey-900',
                textColor: 'default'
            },
            'xxx-#cfd8dc': {
                colorClass: 'mdColor_blueGrey-A100',
                textColor: 'default'
            },
            'xxx-#b0bec5': {
                colorClass: 'mdColor_blueGrey-A200',
                textColor: 'default'
            },
            'xxx-#78909c': {
                colorClass: 'mdColor_blueGrey-A400',
                textColor: 'default'
            },
            'xxx-#455a64': {
                colorClass: 'mdColor_blueGrey-A700',
                textColor: 'default'
            },
            'xxx-#000000': {
                colorClass: 'mdColor_black',
                textColor: 'default'
            },
            '#ffffff': {
                colorClass: 'mdColor_white',
                textColor: 'dark'
            }
        }
    };

    function TbgMdColor (hex) {
        if (!arguments.length) {
            throw new Error('TbgMdColor constructor expects one argument, but none were passed.');
        }

        this.classname = getClass(hex);
    }

    function getClass (hex) {
        var colorObj = colors.colorClasses[hex] || null;
        var classes = [colors.textClasses.base];

        if (!colorObj) {
            throw new Error('Could not find color object with key "' + hex + '" in tbgMdColorClasses dictionary.');
        }

        classes.push(colorObj.colorClass);
        classes.push(colors.textClasses[colorObj.textColor]);
        return classes.join(' ');
    }
}());
