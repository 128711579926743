(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .controller('ProductionTaskSpecificationsEditController', ProductionTaskSpecificationsEditController);

    ProductionTaskSpecificationsEditController.$inject = ['$uibModalInstance', 'supplierId', 'operationId', 'operationItemId', 'format', 'UtilService', 'ProductionTaskSpecificationService'];

    function ProductionTaskSpecificationsEditController ($uibModalInstance, supplierId, operationId, operationItemId, format, UtilService, ProductionTaskSpecificationService) {
        var ctrl = this;

        var typesSf = [
            {
                id: 'FIXED_AMOUNT',
                name: 'Fixed amount'
            },
            {
                id: 'PRESS_SHEET_MULTIPLIER',
                name: 'Per press sheet'
            },
            {
                id: 'COVER_PRESS_SHEET_MULTIPLIER',
                name: 'Per cover press sheet'
            },
            {
                id: 'QUANTITY_MULTIPLIER',
                name: 'Per each finished quantity'
            },
            {
                id: 'PIECES_MULTIPLIER',
                name: 'Per piece'
            },
            {
                id: 'CUT_BLADE_DROPS_MULTIPLIER',
                name: 'Per each blade drop (estimated) when cutting'
            },
            {
                id: 'PIECE_PERIMETER_INCHES_MULTIPLIER',
                name: 'Per total perimeter inches per piece'
            },
            {
                id: 'PIECE_SQUARE_INCHES_MULTIPLIER',
                name: 'Per square inch per piece'
            },
            {
                id: 'PRESS_SHEET_SQUARE_INCHES_MULTIPLIER',
                name: 'Per square inch per press sheet'
            },
            {
                id: 'COVER_PRESS_SHEET_SQUARE_INCHES_MULTIPLIER',
                name: 'Per square inch per cover press sheet'
            },
            {
                id: 'PIECES_ADD_ON_OPERATION_ANSWER_MULTIPLIER',
                name: 'Per piece * Add-On operation answer'
            }
        ];

        var typesLf = [
            {
                id: 'FIXED_AMOUNT',
                name: 'Fixed amount'
            },
            {
                id: 'ORDERED_QUANTITY_MULTIPLIER',
                name: 'Per each ordered quantity'
            },
            {
                id: 'QUANTITY_MULTIPLIER',
                name: 'Per each quantity including overs'
            },
            {
                id: 'ADD_ON_OPERATION_ANSWER_MULTIPLIER',
                name: '* Add-On operation answer'
            },
            {
                id: 'QUANTITY_ADD_ON_OPERATION_ANSWER_MULTIPLIER',
                name: 'Per finished quantity * Add-On operation answer'
            },
            {
                id: 'OPERATION_QUANTITY_DIVISOR',
                name: 'Per finished quantity / Operation quantity divisor'
            },
            {
                id: 'TOTAL_SQUARE_FEET_MULTIPLIER',
                name: 'Per total square feet'
            },
            {
                id: 'TOTAL_LINEAR_FEET_MULTIPLIER',
                name: 'Per total linear feet'
            },
            {
                id: 'SIDE_LINEAR_FEET_MULTIPLIER',
                name: 'Per total linear feet, only selected sides'
            },
            {
                id: 'OPERATION_QUANTITY_MULTIPLIER',
                name: 'Per operation quantity * production quantity'
            },
            {
                id: 'OPERATION_ORDERED_QUANTITY_MULTIPLIER',
                name: 'Per operation quantity * ordered quantity'
            }
        ];

        ctrl.supplierId = supplierId;
        ctrl.operationId = operationId;
        ctrl.operationItemId = operationItemId;
        ctrl.taskSpecifications = [];
        ctrl.format = format;
        ctrl.types = [];
        ctrl.taskTypes = [];
        ctrl.submitted = false;
        ctrl.addTask = addTask;
        ctrl.deleteTask = deleteTask;
        ctrl.addFormula = addFormula;
        ctrl.deleteFormula = deleteFormula;
        ctrl.addMaterial = addMaterial;
        ctrl.deleteMaterial = deleteMaterial;
        ctrl.save = save;

        init();

        function init () {
            if (ctrl.format == 'large') {
                ctrl.types = typesLf;
            } else if (ctrl.format == 'small') {
                ctrl.types = typesSf;
            }

            ProductionTaskSpecificationService
                .getTaskSpecificationsForOperationItemId(ctrl.supplierId, ctrl.operationItemId, ctrl.format)
                .then(function (response) {
                    ctrl.taskSpecifications = response.data.content;
                });

            ProductionTaskSpecificationService
                .getAllProductionTaskTypes()
                .then(function (response) {
                    var tempTaskTypes = UtilService.unpackListResponse(response, 'productionTaskTypes');
                    angular.forEach(tempTaskTypes, function (type) {
                        var description = type.description ? ' - ' + type.description : '';
                        var category = type.productionTaskCategory.id && type.productionTaskCategory.id != 'PRODUCTION' ? ' (' + type.productionTaskCategory.name + ')' : '';
                        type.fullName = type.name + description + category;
                        if (!type.actionable) {
                            type.fullName += ' (Not Actionable)';
                        }
                    });
                    ctrl.taskTypes = tempTaskTypes;
                });
        }

        function addTask () {
            var newTask = {
                productionTimeFormulas: [],
                productionTaskMaterialSpecifications: []
            };
            ctrl.taskSpecifications.push(newTask);
        }

        function deleteTask (task, index) {
            ctrl.taskSpecifications.splice(index, 1);
        }

        function addFormula (taskSpecification) {
            var newFormula = {
                name: '',
                type: null,
                value: null
            };
            taskSpecification.productionTimeFormulas.push(newFormula);
        }

        function deleteFormula (taskSpecification, formula, index) {
            taskSpecification.productionTimeFormulas.splice(index, 1);
        }

        function addMaterial (taskSpecification) {
            var newMaterial = {
                materialType: null,
                materialUnitOfMeasure: null,
                referenceId: null,
                name: null,
                quantity: null,
                active: true
            };
            if (!taskSpecification.productionTaskMaterialSpecifications) {
                taskSpecification.productionTaskMaterialSpecifications = [];
            }
            taskSpecification.productionTaskMaterialSpecifications.push(newMaterial);
        }

        function deleteMaterial (taskSpecification, material, index) {
            taskSpecification.productionTaskMaterialSpecifications.splice(index, 1);
        }

        function save (taskSpecs) {
            ctrl.submitted = true;
            if (validate(taskSpecs)) {
                ctrl.saving = true;
                ProductionTaskSpecificationService.saveTaskSpecifications(ctrl.operationItemId, taskSpecs, ctrl.format).then(function (response) {
                    $uibModalInstance.close();
                    editClicked(ctrl.operationId);
                });
            }
        }

        function validate (taskSpecs) {
            var value = true;
            ctrl.errorMessages = {};
            angular.forEach(taskSpecs, function (taskSpec) {
                taskSpec.errorMessages = {};
                if (!taskSpec.productionTaskType) {
                    taskSpec.errorMessages.taskTypeRequiredError = true;
                    value = false;
                }
                angular.forEach(taskSpec.productionTimeFormulas, function (timeFormula) {
                    timeFormula.errorMessages = {};
                    if (!timeFormula.type) {
                        timeFormula.errorMessages.formulaTypeRequiredError = true;
                        value = false;
                    }
                    if (!timeFormula.value) {
                        timeFormula.errorMessages.formulaValueRequiredError = true;
                        value = false;
                    }
                });
            });
            return value;
        }
    }
}());
