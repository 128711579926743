(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('SiteFavoriteService', SiteFavoriteService);

    SiteFavoriteService.$inject = ['$http', '$q', 'restRootUrl'];

    function SiteFavoriteService ($http, $q, restRootUrl) {
        var service = {
            browseSiteFavorites: browseSiteFavorites,
            createSiteFavorite: createSiteFavorite,
            deleteSiteFavorite: deleteSiteFavorite
        };

        return service;

        function browseSiteFavorites (filter, page, size, sort, ascending) {
            var ascDesc = ascending ? 'ASC' : 'DESC';
            var q = $http.ioHelpers.toQueryString(filter);
            var requestConfig = {
                method: 'GET',
                url: restRootUrl + '/adminUserSiteFavs/search',
                params: {
                    // paging stuff
                    page: page - 1,
                    size: size,

                    // sorting stuff
                    sort: sort + ',' + ascDesc,
                    q: q,
                    embeddedAssociations: 'site,site.supplier'
                }
            };

            return $http(requestConfig).catch(browseSiteFavoritesError);

            function browseSiteFavoritesError (response) {
                return $q.reject('Unexpected error searching for site favorites.');
            }
        }

        function createSiteFavorite (supplierId, siteId, adminUserId) {
            var requestConfig = {
                url: restRootUrl + '/adminUserSiteFavs',
                method: 'POST',
                data: {
                    supplierId: supplierId,
                    siteId: siteId,
                    adminUserId: adminUserId
                }
            };

            return $http(requestConfig).catch(createUserError);

            function createUserError (response) {
                return $q.reject('Unexpected error creating the admin user. ' + response.data.message);
            }
        }

        function deleteSiteFavorite (id) {
            var requestConfig = {
                url: restRootUrl + '/adminUserSiteFavs/' + id,
                method: 'DELETE'
            };

            return $http(requestConfig).catch(deleteUserError);

            function deleteUserError (response) {
                return $q.reject('Error deleting the site favorite.');
            }
        }
    }
}());
