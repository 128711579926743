(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .constant('permissions', {
            READ: 'canRead',
            CREATE: 'canCreate',
            EDIT: 'canEdit'
        });
}());
