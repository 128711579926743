/*
 * Copyright 2024 Collaterate. All rights reserved.
 */

(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('localStorageKeys', localStorageKeys);

    localStorageKeys.$inject = [];

    function localStorageKeys () {
        return {
            compositeJob_productionGroupId: 'compositeJob_productionGroupId',
            compositeJob_autoRefresh: 'compositeJob_autoRefresh',
            compositeJob_gangableSortProperty: 'compositeJob_gangableSortProperty',
            compositeJob_gangableSortReversed: 'compositeJob_gangableSortReversed',
            compositeJob_compositeSortProperty: 'compositeJob_compositeSortProperty',
            compositeJob_compositeSortReversed: 'compositeJob_compositeSortReversed',
            compositeJob_activeTab: 'compositeJob_activeTab',

            customProjectQuotes_initialized: 'customProjectQuotes_initialized',
            customProjectQuotes_followedUserIds: 'customProjectQuotes_followedUserIds',
            customProjectQuotes_statusFilter: 'customProjectQuotes_statusFilter',
            customProjectQuotes_sortState: 'customProjectQuotes_sortState',
            customProjectQuotes_searchText: 'customProjectQuotes_searchText',
            customProjectQuotes_estimatorFilter: 'customProjectQuotes_estimatorFilter',
            customProjectQuotes_emailFrom: 'customProjectQuotes_emailFrom',
            customProjectQuotes_secondaryFilter: 'customProjectQuotes_secondaryFilter',
            customProjectQuotes_favorites: 'customProjectQuotes_favorites',
            customProjectQuotes_lastSiteId: 'customProjectQuotes_lastSiteId',

            jobQueues_production_selectedCsr: 'jobQueues_production_selectedCsr',
            jobQueues_production_selectedProductionStatuses: 'jobQueues_production_selectedProductionStatuses',
            jobQueues_production_selectedProductionGroups: 'jobQueues_production_selectedProductionGroups',
            jobQueues_production_selectedGangStatus: 'jobQueues_production_selectedGangStatus',

            jobQueues_prepress_sortReverse: 'jobQueues_prepress_sortReverse',
            jobQueues_prepress_sortProperty: 'jobQueues_prepress_sortProperty',
            jobQueues_prepress_flaggedForNewFileUpload: 'jobQueues_prepress_flaggedForNewFileUpload',
            jobQueues_prepress_flaggedForPrepressAlert: 'jobQueues_prepress_flaggedForPrepressAlert',
            jobQueues_prepress_selectedCsrUsers: 'jobQueues_prepress_selectedCsrUsers',
            jobQueues_prepress_selectedPrepressTech: 'jobQueues_prepress_selectedPrepressTech',
            jobQueues_prepress_proofStatusId: 'jobQueues_prepress_proofStatusId',
            jobQueues_prepress_orderNumber: 'jobQueues_prepress_orderNumber',
            jobQueues_prepress_jobNumberDisplay: 'jobQueues_prepress_jobNumberDisplay',
            jobQueues_prepress_jobName: 'jobQueues_prepress_jobName',
            jobQueues_prepress_customerName: 'jobQueues_prepress_customerName',
            jobQueues_prepress_siteName: 'jobQueues_prepress_siteName',
            jobQueues_prepress_issueJobs_dd: 'jobQueues_prepress_issueJobs_dd',
            jobQueues_prepress_dataJobs_dd: 'jobQueues_prepress_dataJobs_dd',
            jobQueues_prepress_layoutServicesJobs_dd: 'jobQueues_prepress_layoutServicesJobs_dd',
            jobQueues_prepress_storefrontJobs_dd: 'jobQueues_prepress_storefrontJobs_dd',

            notifications_selectedVisibility: 'notifications_selectedVisibility',

            division_list_filters: 'division_list_filters'
        };
    }
}());
