angular.module('collaterateApp').service('CostTableService', [
    '$http', 'growlNotifications', 'restRootUrl', function ($http, growlNotifications, restRootUrl) {
        return {
            getCostTable: function (id) {
                return $http.get(restRootUrl + '/v2/costTables/' + id);
            },
            createCostTable: function (costTable) {
                return $http({
                    url: restRootUrl + '/v2/costTables',
                    method: 'POST',
                    data: costTable
                })
                    .success(function (data, status, headers, config) {
                    })
                    .error(function (data, status, headers, config) {
                        growlNotifications.add('Unexpected error creating the cost table.', 'warning');
                    });
            },
            updateCostTable: function (costTable) {
                return $http({
                    url: restRootUrl + '/v2/costTables/' + costTable.id,
                    method: 'PUT',
                    data: costTable
                })
                    .success(function (data, status, headers, config) {
                    })
                    .error(function (data, status, headers, config) {
                        growlNotifications.add('Unexpected error updating the cost table.', 'warning');
                    });
            },
            deleteCostTable: function (costTable) {
                return $http({
                    url: restRootUrl + '/v2/costTables/' + costTable.id,
                    method: 'DELETE',
                    data: costTable
                })
                    .success(function (data, status, headers, config) {
                    })
                    .error(function (data, status, headers, config) {
                        growlNotifications.add('Error deleting the cost table. It may be in-use and therfore cannot be deleted.', 'warning');
                    });
            }
        };
    }
]);
