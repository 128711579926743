(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .constant('chartTypes', {
            PRODUCT_PRICE__LINE: 'PRODUCT_PRICE__LINE',
            PRODUCT_PRICE_EDIT__LINE: 'PRODUCT_PRICE_EDIT__LINE'
        });
}());
