(function () {
    'use strict';

    /*
      Use this directive on inputs. Listens for specified event on $rootScope and will
      focus itself when the event is detected.

      <input autofocus-on="someEventName" />

      // $rootScope.$emit('someEventName')

    */

    angular
        .module('collaterateApp')
        .directive('autofocusOn', autofocusOn);

    autofocusOn.$inject = ['$rootScope', '$timeout'];

    function autofocusOn ($rootScope, $timeout) {
        return {
            restrict: 'A',
            scope: {
                whichEvent: '@autofocusOn'
            },
            link: function (scope, element, attrs) {
                var destroy = $rootScope.$on(scope.whichEvent, focusElement);
                scope.$on('$destroy', destroy);

                function focusElement () {
                    $timeout(function () {
                        element.focus();
                    });
                }
            }
        };
    }
}());
