(function () {
    'use strict';

    var ASCENDING = 'ASC';

    angular
        .module('collaterate.common')
        .factory('PaginatedList', getPaginatedListConstructor);

    getPaginatedListConstructor.$inject = [];

    function getPaginatedListConstructor () {
        function PaginatedList (paginatedResponse, Constructor) {
            var primarySortOptions = paginatedResponse.page.sort.slice(0, 1)[0];
            var secondarySortOptions = paginatedResponse.page.sort.slice(1);

            this.items = Constructor
                ? paginatedResponse.content.map(function (item) {
                    return new Constructor(item);
                })
                : paginatedResponse.content;
            this.page = {
                number: paginatedResponse.page.number,
                size: paginatedResponse.page.size,
                totalPages: paginatedResponse.page.totalPages,
                totalElements: paginatedResponse.page.totalElements,
                sort: {
                    fieldName: primarySortOptions.fieldName,
                    ascending: primarySortOptions.direction === ASCENDING
                },
                secondarySorts: secondarySortOptions.map(function (sort) {
                    return {
                        fieldName: sort.fieldName,
                        ascending: sort.direction === ASCENDING
                    };
                })
            };

            this.next = this.page.number < this.page.totalPages;
            this.previous = this.page.number > 1;
        }

        return PaginatedList;
    }
}());
