(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .constant('paginationDefaults', {
            number: 1,
            size: 25

            // sort: { fieldName: 'id', ascending: true },
        });
}());
