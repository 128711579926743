/*
    Renders a toggle switch based on a Boolean model value.

    Example:
    <input-toggle
        ng-model="vm.someBooleanModel" (required)
        ng-change="vm.onToggle()" (optional)
        ng-disabled="vm.shouldBeDisabled" (optional)
        theme="retro" (optional) - current available values are: "retro"
        size="sm" (optional) - current available values are: "sm", "lg"
        name="someName" (optional)
    ></input-toggle>
*/

(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .directive('inputToggle', inputToggle);

    inputToggle.inject = [];

    function inputToggle () {
        return {
            restrict: 'AE',
            replace: true,
            transclude: true,
            templateUrl: '/templates/input-toggle.template.html',
            require: 'ngModel',
            scope: {},
            link: function (scope, element, attrs, ngModelController) {
                scope.modelValue = null;
                scope.themeClass = attrs.theme ? 'theme--inputToggle_' + attrs.theme : '';
                scope.sizeClass = attrs.size ? 'inputToggle_' + attrs.size : '';

                ngModelController.$render = function () {
                    scope.modelValue = ngModelController.$viewValue;
                };

                scope.onClick = function () {
                    if (attrs.disabled) {
                        return;
                    }

                    ngModelController.$setViewValue(!ngModelController.$viewValue);
                    ngModelController.$render();
                };
            }
        };
    }
}());
