(function () {
    'use strict';

    var ROOT_URL = null;

    angular
        .module('collaterateApp')
        .provider('CollaterateSecurityService', CollaterateSecurityServiceProvider);

    function CollaterateSecurityServiceProvider () {
        return {
            setRootUrl: setRootUrl,
            $get: CollaterateSecurityService
        };

        function setRootUrl (url) {
            if (!arguments.length || url.constructor !== String) {
                throw new Error('The CollaterateSecurityServiceProvider.setRootUrl method requires a URL argument (String)');
            }

            ROOT_URL = url;
        }
    }

    CollaterateSecurityService.$inject = ['$http', 'UserTypes', '$q'];

    function CollaterateSecurityService ($http, UserTypes, $q) {
        var usersPrefix = '/users/';
        var adminUsersPrefix = '/adminUsers/';
        var securedEntitiesRestEndpoint = '/securedEntityPermissions';
        var securedEntities = [];
        var timestamp = null;
        var user = null;
        var getEntitiesPromise = null;

        return {
            setUser: setUser,
            hasPermission: hasPermission,
            hasPermissionAsync: hasPermissionAsync,
            getEntities: getEntities,
            lastUpdated: lastUpdated
        };

        function setUser (restUser) {
            if (!restUser.restUserId) {
                return $q.reject('CollaterateSecurityService.setUser(restuUser) expects restUser.restUserId to be set.');
            }

            if (!restUser.restUserType) {
                return $q.reject('CollaterateSecurityService.setUser(restuUser) expects restUser.restUserType to be set.');
            }

            user = restUser;
            return loadSecuredEntities();
        }

        function loadSecuredEntities () {
            if (!ROOT_URL) {
                return $q.reject('>>> CollaterateSecurityService : ROOT_URL has not been set : This needs to be called in your app "config" setup "CollaterateSecurityServiceProvider.setRootUrl(restRootUrl).  Where "restRootUrl" looks something like "http://collaterate.com/rest/v1/supplierAdmin"');
            }

            var prefix = null;

            switch (user.restUserType) {
                case UserTypes.STOREFRONT:
                    prefix = usersPrefix;
                    break;
                case UserTypes.ADMIN:
                    prefix = adminUsersPrefix;
                    break;
                default:
                    return $q.reject('>>> CollaterateSecurityService : Invalid user type specified (' + user.restUserType + ').  Use either ' + UserTypes.STOREFRONT + ' or ' + UserTypes.ADMIN);
                    break;
            }

            if (user.restUserId < 1) {
                return $q.when({ data: {}});
            }

            if (!getEntitiesPromise) {
                return getEntitiesPromise = getEntitiesForUser();
            }

            return getEntitiesPromise;

            function getEntitiesForUser () {
                return $http.get(ROOT_URL + prefix + user.restUserId + securedEntitiesRestEndpoint)
                    .then(resolveSecuredEntities)
                    .catch(handleRequestError);
            }

            function resolveSecuredEntities (response) {
                securedEntities = response.data;
                timestamp = new Date().getTime();
                return securedEntities;
            }

            function handleRequestError (response) {
                return $q.reject('An error occurred loading secured entities for user.');
            }
        }

        function getEntities () {
            if (!user) {
                return $q.reject('User not set.');
            }

            if (getEntitiesPromise) {
                return getEntitiesPromise;
            }

            return $q.resolve(securedEntities);
        }

        function hasPermission (entityName, permissionName) {
            if (!user.restUserId || !entityName || !permissionName) {
                return false;
            }

            for (var i = 0; i < securedEntities.length; i++) {
                if (securedEntities[i].entityName === entityName) {
                    return securedEntities[i][permissionName];
                }
            }

            return false;
        }

        function hasPermissionAsync (entityName, permissionName) {
            return getEntitiesPromise
                .then(function () {
                    return hasPermission(entityName, permissionName);
                });
        }

        // Used to notify watchers,
        // Keeps the permissions store private and is much less
        // overhead than watching the giant collection would be
        function lastUpdated () {
            return timestamp;
        }
    }
}());
