window.tbg = window.tbg || {};
window.tbg.apiV2 = (function (httpService, growlMessages) {
    'use strict';

    if (!httpService || !growlMessages) {
        console.warn('Could not initialize apiV2 service. Dependencies not met.');
        return;
    }

    return function apiV2 (requestConfig) {
        return httpService(requestConfig)
            .then(handleApiMessage)
            .then(handleApiResponse)
            .catch(handleApiError);

        function handleApiMessage (response) {
            if (response.messages && response.messages.length) {
                response.messages.forEach(function (message) {
                    switch (message.type) {
                        case 'SUCCESS':
                            growlMessages.addInfoMessage(message.detail);
                            break;
                        case 'INFO':
                            growlMessages.addWarningMessage(message.detail);
                            break;
                        case 'WARNING':
                            growlMessages.addErrorMessage(message.detail);
                            break;
                    }
                });

                growlMessages.showQueue();
            }

            return response;
        }

        function handleApiResponse (response) {
            return response && response.content || response;
        }

        function handleApiError (response) {
            // TODO: Implement Input and Response error types...
            if (response.errors) {
                response.errors.forEach(function (e) {
                    growlMessages.addErrorMessage(e.detail);
                });
            } else {
                growlMessages.addErrorMessage(response);
            }

            growlMessages.showQueue();

            throw response;
        }
    };

}(window.tbg.httpService, window.growlMessages || new GrowlMessages('userMessages', 'userMessageContent', 'growlMessages')));
