(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('sessionStorageKeys', sessionStorageKeys);

    sessionStorageKeys.$inject = [];

    function sessionStorageKeys () {
        return {
            // Master Product List Page
            masterProductList_searchCriteria: 'masterProductList_searchCriteria',
            masterProductList_sortProps: 'masterProductList_sortProps',

            // Product List Pages
            siteProductList_activeView: 'siteProductList_activeView',
            siteProductList_lastSiteId: 'siteProductList_lastSiteId',
            allSiteProductsList_searchCriteria: 'allSiteProductsList_searchCriteria',
            allSiteProductsList_sortProps: 'allSiteProductsList_sortProps',
            podSiteProductsList_searchCriteria: 'podSiteProductsList_searchCriteria',
            podSiteProductsList_sortProps: 'podSiteProductsList_sortProps',
            stockSiteProductsList_searchCriteria: 'stockSiteProductsList_searchCriteria',
            stockSiteProductsList_sortProps: 'stockSiteProductsList_sortProps'
        };
    }
}());
