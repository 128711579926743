(function () {
    'use strict';

    /*
        Capitalizes the first letter in each word of a string.

        "TEST" --> "Test"
        "test" --> "Test"
        "multiple words" --> "Multiple Words"
    */

    angular
        .module('collaterateApp')
        .filter('capitalize', capitalize);

    function capitalize () {
        return function (str) {
            if (!str) {
                return str;
            }

            return str.split(' ').map(function (word) {
                return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
            }).join(' ');
        };
    }
}());
