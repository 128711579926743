(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('productionTaskSpecificationsView', productionTaskSpecificationsView);

    function productionTaskSpecificationsView () {
        return {
            restrict: 'EA',
            scope: {
                supplierId: '@',
                operationId: '@',
                operationItemId: '@',
                format: '@'
            },
            templateUrl: '/templates/production-task-specifications-view.template.html'
        };
    }
}());
