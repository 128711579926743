window.tbg = window.tbg || {};
window.tbg.httpService = (function ($, Promise, REST_ROOT_URL) {
    'use strict';

    if (!$ || !Promise || !REST_ROOT_URL) {
        console.warn('Could not initialize httpService. Dependencies not met.');
        return;
    }

    return function httpService (config) {
        var request;
        var requestConfig = Object.assign({ method: 'GET' }, config, { url: REST_ROOT_URL + config.url });

        if (requestConfig.params) {
            requestConfig.data = Object.assign({}, requestConfig.data, requestConfig.params);
        }

        request = $.ajax(requestConfig);

        return new Promise(function (resolve, reject) {
            request.then(resolve, function errorResponse (jqXHR, textStatus, e) {
                reject(jqXHR.responseJSON || e);
            });
        });
    };

}(window.jQuery, window.Promise, window.REST_ROOT_URL || '/rest/storefront')); // TODO: Put storefront rest root url global as well...
