(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('bulkActionsService', bulkActionsService);

    bulkActionsService.$inject = ['BulkActions'];

    function bulkActionsService (BulkActions) {
        var bulkActionsInstances = {};

        var service = {
            addInstance: addInstance,
            getInstance: getInstance,
            instanceExists: instanceExists,
            removeInstance: removeInstance
        };

        return service;

        function addInstance (id) {
            if (!instanceExists(id)) {
                bulkActionsInstances[id] = new BulkActions(id);
            }
        }

        function getInstance (id) {
            return bulkActionsInstances[id] || null;
        }

        function instanceExists (id) {
            return typeof bulkActionsInstances[id] !== 'undefined';
        }

        function removeInstance (id) {
            delete bulkActionsInstances[id];
        }
    }
}());
