(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('prepressTechService', prepressTechService);

    prepressTechService.$inject = ['$http', '$q', 'restRootUrl'];

    function prepressTechService ($http, $q, restRootUrl) {
        var allPrepressTechServiceEndpoint = restRootUrl + '/users/prepressTech/all';

        var service = {
            getAll: getAll
        };

        return service;

        function getAll () {
            return $http
                .get(allPrepressTechServiceEndpoint)
                .catch(handleError);

            function handleError (response) {
                return $q.reject('Unexpected error getting all prepress techs.');
            }
        }
    }
}());
