(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('chip', chip);

    function chip () {
        return {
            restrict: 'AE',
            replace: true,
            transclude: true,
            templateUrl: '/templates/chip.template.html',
            scope: {
                onClose: '&'
            },
            link: function (scope, element, attrs) {
                scope.showCloseBtn = attrs.hasOwnProperty('onClose'); // only show the close button if a onClose attr has been set
            }
        };
    }
}());

