(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .filter('markdown', markdown);

    function markdown () {
        return function(input) {
            if (!input) return '';
            var converter = new showdown.Converter();
            return converter.makeHtml(input);
        };
    }
}());
