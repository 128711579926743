/*
 * Copyright 2024 Collaterate. All rights reserved.
 */

angular.module('collaterateApp').controller('ChangeProductionStatusCtrl', [
    '$rootScope', '$scope', '$timeout', 'OrderItemService', function ($rootScope, $scope, $timeout, OrderItemService) {
        var planning = { id: 5, name: 'Planning' };
        var productionReady = { id: 1, name: 'Production Ready', previous: planning };
        var queuedForPrint = { id: 4, name: 'Queued For Print', previous: productionReady };
        var printed = { id: 2, name: 'Printed', previous: queuedForPrint };
        var finished = { id: 3, name: 'Finished', previous: printed };

        $scope.statuses = [planning, productionReady, queuedForPrint, printed, finished];

        $scope.selectedStatus = $scope.statuses.find(function (status) {
            return status.id == $scope.item.productionStatusId;
        });

        // This function returns the 'status-set' class name if the status came before or is the active status.
        $scope.getClass = function (activeStatus, status) {
            if (activeStatus == status) {
                return 'status-set';
            }
            if (activeStatus && activeStatus.previous && activeStatus.previous != undefined) {
                return $scope.getClass(activeStatus.previous, status);
            }
            return '';
        };
        $scope.save = function (item) {
            OrderItemService
                .updateProductionStatus(item.id.orderItemId, $scope.selectedStatus.id)
                .then(function (response) {
                    $rootScope.$broadcast('productionStatusUpdated', {
                        jobNumberDisplay: item.jobNumberDisplay,
                        productionStatusId: $scope.selectedStatus.id,
                        productionStatusName: $scope.selectedStatus.name
                    });
                });
            dismissPopover();
        };
        $scope.cancel = function () {
            $scope.selectedStatus = $scope.statuses.find(function (status) {
                return status.id == $scope.item.productionStatusId;
            });

            dismissPopover();
        };
        $scope.select = function (selectedStatus) {
            if (selectedStatus == $scope.selectedStatus) {
                if (selectedStatus.previous != undefined) {
                    selectedStatus = selectedStatus.previous;
                }
            }

            $scope.selectedStatus = selectedStatus;
        };

        function dismissPopover () {
            $timeout(function () {
                angular.element('#productionStatus_' + $scope.item.jobNumberDisplay).scope().showPopover = false;
            }, 1);
        }
    }
]);
