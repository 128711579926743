(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('ProductPriceEditLineChart', getProductPriceEditLineChartConstructor);

    getProductPriceEditLineChartConstructor.$inject = ['ProductPriceLineChart'];

    function getProductPriceEditLineChartConstructor (ProductPriceLineChart) {
        var superclass = ProductPriceLineChart;
        ProductPriceEditLineChart.prototype = new ProductPriceLineChart();
        ProductPriceEditLineChart.prototype.constructor = ProductPriceEditLineChart;

        function ProductPriceEditLineChart (data) {
            var config = {
                chart: {
                    width: 618
                }
            };

            superclass.call(this, data, config);
        }

        return ProductPriceEditLineChart;
    }
}());
