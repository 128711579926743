BaseCostTableCtrl.$inject = ['$scope', '$filter'];
function BaseCostTableCtrl ($scope, $filter) {
    $scope.endpointNumber = function (point) {
        return Number(point.endpoint);
    };
    $scope.getLabelForIndex = function (index) {
        var points = $filter('orderBy')($scope.costTable.costTablePoints, $scope.endpointNumber);
        var point = null;
        var indexLocal = 0;
        var beginPoint = 0;
        angular.forEach(points, function (value, key) {
            if (indexLocal == index) {
                point = value;
                if (index == 0) {
                    beginPoint = 1;
                } else {
                    beginPoint = Number(points[index - 1].endpoint) + 1;
                }
            }
            indexLocal++;
        });
        return beginPoint + ' ---> ' + point.endpoint;
    };
    $scope.getInfinityLabel = function () {
        var lastEndPointValue = 0;
        var index = 0;
        if ($scope.costTable.costTablePoints) {
            var points = $filter('orderBy')($scope.costTable.costTablePoints, $scope.endpointNumber);
            index = points.length;
            var lastEndPoint = points[index - 1];
            if (lastEndPoint) {
                lastEndPointValue = lastEndPoint.endpoint;
            }
        }
        return (Number(lastEndPointValue) + 1) + ' ---> Infinity';
    };
}
angular.module('collaterateApp').controller('CostTableViewCtrl', [
    '$injector', '$scope', '$filter', '$uibModal', 'CostTableService', function ($injector, $scope, $filter, $uibModal, CostTableService) {
        $scope.costTable = {};
        $scope.isNew = false;

        // inherit the base controller functionality
        $injector.invoke(BaseCostTableCtrl, this, {
            $scope: $scope,
            $filter: $filter
        });

        $scope.edit = function (costTableId) {
            $uibModal.open({
                templateUrl: '/templates/cost-table-edit.template.html',
                windowClass: 'small-modal',
                backdrop: 'static',
                controller: 'CostTableEditCtrl',
                resolve: {
                    costTableId: function () {
                        return costTableId == '' || costTableId == null ? null : costTableId;
                    },
                    hiddenInputName: function () {
                        return $scope.hiddenInputName;
                    }
                }
            });
        };

        var initializeView = function (costTableId) {
            $scope.isNew = false;
            $scope.costTableId = costTableId;
            CostTableService.getCostTable(costTableId).then(function (response) {
                $scope.costTable = response.data.result;
            });
        };

        $scope.$on('refreshCostTableView', function (event, data) {
            if ($scope.hiddenInputName == data.hiddenInputName) {
                // update the hidden field that is used to capture the cost table id in the classic world.
                $('input[name="' + data.hiddenInputName + '"]').val(data.costTableId);
                initializeView(data.costTableId);
            }
        });

        if ($scope.costTableId != null && $scope.costTableId != '') {
            initializeView($scope.costTableId);
        } else {
            $scope.isNew = true;
        }
    }
])

    .controller('CostTableEditCtrl', [
        '$injector', '$rootScope', '$scope', '$filter', '$uibModal', 'CostTableService', 'costTableId', 'hiddenInputName', function ($injector, $rootScope, $scope, $filter, $uibModal, CostTableService, costTableId, hiddenInputName) {
            $scope.costTableId = costTableId;
            $scope.hiddenInputName = hiddenInputName;
            $scope.costTable = {};
            $scope.isNew = true;

            // inherit the base controller functionality
            $injector.invoke(BaseCostTableCtrl, this, {
                $scope: $scope,
                $filter: $filter
            });

            var successfullDismiss = function (costTableId) {
                $rootScope.$broadcast('refreshCostTableView', {
                    costTableId: costTableId,
                    hiddenInputName: $scope.hiddenInputName
                });
                $scope.$dismiss();
            };

            $scope.saveCostTable = function (form, costTable) {
                if (form.$invalid) {
                    return;
                }

                if (costTable.id != null) {
                    CostTableService.updateCostTable(costTable).then(function (response) {
                        successfullDismiss(costTable.id);

                        // This is a call to the legacy growlMessage object.  In the future use the growlMessages service.
                        growlMessages.showInfoMessage('The cost table was successfully saved.');
                    });
                } else {
                // execute create of cost table and press sheet
                    CostTableService.createCostTable(costTable).then(function (response) {
                        successfullDismiss(response.data.result.id);

                        // This is a call to the legacy growlMessage object.  In the future use the growlMessages service.
                        growlMessages.showWarningMessage('You will need to save the entity for the cost table changes to become permanent.');
                    });
                }
            };
            $scope.addPoint = function () {
                var points = $filter('orderBy')($scope.costTable.costTablePoints, $scope.endpointNumber);
                if (points == null) {
                    points = [];
                }
                index = points.length;
                var lastEndPointValue = 1;
                var lastEndPoint = points[index - 1];
                if (lastEndPoint) {
                    lastEndPointValue = lastEndPoint.endpoint;
                }
                var newPoint = {
                    endpoint: Number(lastEndPointValue) + 1,
                    cost: 0,
                    flatPrice: false
                };
                points.push(newPoint);
                points = $filter('orderBy')(points, $scope.endpointNumber);
                $scope.costTable.costTablePoints = points;
            };
            $scope.deletePoint = function (point) {
                $scope.costTable.costTablePoints.splice($scope.costTable.costTablePoints.indexOf(point), 1);
            };

            if ($scope.costTableId != null) {
            // initialize edit
                $scope.isNew = false;
                CostTableService.getCostTable($scope.costTableId).then(function (response) {
                    $scope.costTable = response.data.result;
                });
            }
        }
    ]);
