(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .directive('bulkActionsSelectAll', bulkActionsSelectAll);

    function bulkActionsSelectAll () {
        return {
            restrict: 'AE',
            replace: true,
            templateUrl: '/templates/bulk-actions-select-all.template.html',
            scope: {
                bulkActionsId: '@',
                watchForReset: '='
            },
            controller: BulkActionsSelectAllController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    BulkActionsSelectAllController.$inject = ['$scope', 'bulkActionsService'];

    function BulkActionsSelectAllController ($scope, bulkActionsService) {
        var vm = this;

        vm.checked = false;

        vm.toggleSelectAll = toggleSelectAll;

        init();

        return;

        function init () {
            $scope.$watch(
                function watchCollection () {
                    return vm.watchForReset;
                },
                function handleCollectionChange (newCollection, oldCollection) {
                    if (!angular.equals(newCollection, oldCollection)) {
                        vm.checked = false;
                    }
                }
            );
        }

        function toggleSelectAll (selectingAll) {
            selectingAll ? selectAll() : deselectAll();
        }

        function selectAll () {
            for (var i = 0; i < bulkActionsService.getInstance(vm.bulkActionsId).controls.length; i++) {
                bulkActionsService.getInstance(vm.bulkActionsId).controls[i].checked = true;
                bulkActionsService.getInstance(vm.bulkActionsId).controls[i].addItem();
            }
        }

        function deselectAll () {
            for (var i = 0; i < bulkActionsService.getInstance(vm.bulkActionsId).controls.length; i++) {
                bulkActionsService.getInstance(vm.bulkActionsId).controls[i].checked = false;
                bulkActionsService.getInstance(vm.bulkActionsId).controls[i].removeItem();
            }
        }
    }
}());
