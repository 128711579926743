(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .factory('OrderLogService', OrderLogService);

    OrderLogService.$inject = ['$http', '$q', '$sce', 'restRootUrl'];

    function OrderLogService ($http, $q, $sce, restRootUrl) {
        var service = {
            getOrderLogForCsrUserId: getOrderLogForCsrUserId,
            getOrderLogForCsrUserIds: getOrderLogForCsrUserIds,
            getOrderLogCountForCsrUserId: getOrderLogCountForCsrUserId,
            getOrderLogCountForCsrUserIds: getOrderLogCountForCsrUserIds,
            dismissOrderLogEntries: dismissOrderLogEntries,
            openOrderLogEntries: openOrderLogEntries,
            respond: respond
        };

        return service;

        function getOrderLogForCsrUserId (csrUserId) {
            return $http.get(restRootUrl + '/orderlog/csr/' + csrUserId);
        }

        function getOrderLogCountForCsrUserId (csrUserId) {
            return $http.get(restRootUrl + '/orderLogEntries/csr/' + csrUserId + '/count');
        }

        // getOrderLog call that supports multiple userIds
        function getOrderLogForCsrUserIds (userIdsCSVList) {
            return $http
                .get(restRootUrl + '/orderLogEntries/csr/search?userids=' + userIdsCSVList)
                .then(filterEmptyCsrs)
                .then(formatNameForOrderLogs)
                .then(trustLogs);

			function filterEmptyCsrs(response) {
				var orderLogs = response.data.result;
				var filteredOrderLogs = [];

				for (var i = 0; i < orderLogs.length; i++) {
					if(orderLogs[i].orderCsrFname && orderLogs[i].orderCsrLname) {
						filteredOrderLogs.push(orderLogs[i]);
					}
				}

				return filteredOrderLogs;
			}

            function formatNameForOrderLogs (orderLogs) {
                for (var i = 0; i < orderLogs.length; i++) {
                    var firstName = orderLogs[i].orderCsrFname;
                    firstName = firstName.substring(0, 1);
                    orderLogs[i].orderCsrFname = firstName + '. ';
                }

                return orderLogs;
            }

            function trustLogs (orderLogs) {
                for (var i = 0; i < orderLogs.length; i++) {
                    orderLogs[i].trustedActionDescription = $sce.trustAsHtml(orderLogs[i].actionDescription);
                }

                return orderLogs;
            }
        }

        // new getOrderLogCount call that supports multiple userIds
        function getOrderLogCountForCsrUserIds (userIdsCSVList) {
            return $http.get(restRootUrl + '/orderLogEntries/csr/search/count?userids=' + userIdsCSVList);
        }

        function dismissOrderLogEntries (csrUserId, toBeDismissed) {
            var requestConfig = {
                url: restRootUrl + '/orderLogEntries/csr/' + csrUserId + '/dismiss',
                method: 'POST',
                data: toBeDismissed
            };

            return $http(requestConfig)
                .catch(handleError);

            function handleError (response) {
                return $q.reject('Unexpected error dismissing the order log entries.');
            }
        }

        function openOrderLogEntries (csrUserId, toBeOpened) {
            var requestConfig = {
                url: restRootUrl + '/orderLogEntries/csr/' + csrUserId + '/open',
                method: 'POST',
                data: toBeOpened
            };

            return $http(requestConfig)
                .catch(handleError);

            function handleError (response) {
                return $q.reject('Unexpected error opening the order log entries.');
            }
        }

        function respond (csrUserId, entryWithResponse) {
            var requestConfig = {
                url: restRootUrl + '/orderLogEntries/csr/' + csrUserId + '/respond',
                method: 'POST',
                data: entryWithResponse
            };

            return $http(requestConfig)
                .catch(handleError);

            function handleError (response) {
                return $q.reject('Unexpected responding to the order log entry.');
            }
        }
    }
}());
